.owl-carousel {
  display: none;
  max-width: 1100px;
  margin: 0 auto;
  z-index: 1;
}

.owl-carousel,
.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  line-height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
}

.owl-carousel .owl-item {
  float: left;
}

.owl-carousel .owl-item img {
  width: 100%;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item {
  height: 496px;
  position: relative;
}

.owl-carousel .owl-item .image {
  position: absolute;
  top: 0;
  left: 0;
}

.owl-carousel .owl-item .info {
  max-width: 525px;
  padding: 59px 40px 33px;
  position: absolute;
  right: -100%;
  bottom: 20px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: none;
}

.owl-carousel .owl-nav {
  display: none;
}

.owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 37px 0 0;
}

.owl-carousel .owl-dots .owl-dot {
  padding: 0 5px;
}

.owl-carousel .owl-dots .owl-dot span {
  display: block;
  width: 8px;
  height: 8px;
  cursor: pointer;
  background: #869098;
  border-radius: 50%;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #e2000f;
}

.owl-carousel .owl-item.active .item .info {
  -webkit-animation: a 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: a 0.5s forwards;
  animation-delay: 0.5s;
  display: block;
}

@keyframes a {
  to {
    right: 20px;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

:-moz-placeholder,
::-moz-placeholder {
  color: #666;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

body input:focus:required:invalid,
body input:required:valid,
body textarea:focus:required:invalid,
body textarea:required:valid {
  color: #666;
}

body,
html {
  height: 100%;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: MyriadProRegular, sans-serif;
  color: #596069;
  overflow-x: hidden;
  margin: 0;
  background-color: white;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.main-container {
  min-height: 100vh;
  display: block;
  position: relative;
  overflow: hidden;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-aligner {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.container-narrow {
  max-width: 860px;
  margin: 0 auto;
}

a {
  font-family: MyriadProBold, sans-serif;
  text-decoration: none;
  color: #1f61a3;
}

ol,
ul {
  list-style-type: none;
  padding: 0;
}

ol,
p,
ul {
  margin: 0;
}

p {
  paddin-top: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: MyriadProBold, sans-serif;
  color: #303844;
}

h1 {
  font-size: 34px;
  margin: 0;
  line-height: 42px;
}

h2 {
  line-height: 36px;
  padding: 0 0 20px;
}

h2,
h3 {
  font-size: 24px;
  margin: 0;
}

h3 {
  line-height: 32px;
  padding: 0 0 16px;
}

h4 {
  margin: 0;
}

.button,
button {
  display: block;
  font-family: MyriadProBold, sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  padding: 18px 30px;
  border-radius: 5px;
  border: none;
  transition: all 0.5s ease;
}

.owl-dots .button,
.owl-dots button {
  background: none;
}

.button.inline,
button.inline {
  display: inline-block;
}

.button.tiny,
button.tiny {
  height: auto;
  line-height: 36px;
  padding: 0 20px;
  font-size: 16px;
}

.preview-video .button.tiny,
.preview-video button.tiny {
  padding-left: inherit;
}

.button.small,
button.small {
  padding: 15px 30px;
  line-height: 19px;
}

.button.red,
button.red {
  background-color: #e2000f;
  color: #fff !important;
}

.button.red:hover,
button.red:hover {
  background-color: #b6000f;
  color: #fff !important;
}

.button.blue,
button.blue {
  background-color: #1f61a3;
  color: #fff;
}

.button.blue:hover,
button.blue:hover {
  background-color: #12406e;
  color: #fff;
}

.button.disabled,
.button.gray,
.button.grey,
.button:disabled,
button.disabled,
button.gray,
button.grey,
button:disabled {
  background-color: #ccd3d8;
}

.button .disabled,
.button:disabled,
button .disabled,
button:disabled {
  cursor: default;
}

.container,
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal,
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 5px solid #e2000f;
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 100px;
  border-bottom: 0 solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 40px;
  margin: -40px -40px -40px auto;
}

.modal-title {
  margin-bottom: 0;
  padding-top: 65px;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 10px 100px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 100px;
  border-top: 0 solid #ebebeb;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.form-control {
  display: block;
  width: 100%;
  padding: 9px 10px;
  font-size: 16px;
  line-height: 20px;
  color: #303844;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #303844;
  background-color: #fff;
  border-color: #46b9f3;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #b0b0b1;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ebebeb;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: 40px;
}

select.form-control:focus::-ms-value {
  color: #303844;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 20px;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 0;
  line-height: 20px;
  color: #303844;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #869098;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d5f2ec;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(213, 242, 236, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #d5f2ec;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #d5f2ec;
  box-shadow: 0 0 0 0.2rem rgba(213, 242, 236, 0.25);
}

.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #d5f2ec;
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #d5f2ec;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label:before {
  background-color: #fcfefe;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #f1f3f5, 0 0 0 0.2rem rgba(213, 242, 236, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #d5f2ec;
}

.custom-file-input.is-valid ~ .custom-file-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(213, 242, 236, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e2000f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(226, 0, 15, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #e2000f;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #e2000f;
  box-shadow: 0 0 0 0.2rem rgba(226, 0, 15, 0.25);
}

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #e2000f;
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #e2000f;
}

.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #ff636d;
}

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label:before {
  background-color: #ff1625;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #f1f3f5, 0 0 0 0.2rem rgba(226, 0, 15, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #e2000f;
}

.custom-file-input.is-invalid ~ .custom-file-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(226, 0, 15, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check,
.table {
  width: 100%;
}

.table {
  max-width: 100%;
  background-color: transparent;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d8d8d9;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d8d8d9;
}

.table tbody + tbody {
  border-top: 2px solid #d8d8d9;
}

.table .table {
  background-color: #f1f3f5;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #d8d8d9;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #c0d3e5;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aec6de;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #dde0e2;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd3d6;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #f3fbfa;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #e0f5f2;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #cbebfc;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e2fb;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f7b8bc;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4a1a6;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #f5f5f5;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8e8e8;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6ccd2;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b8bfc7;
}

.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #f1f3f5;
  background-color: #303844;
  border-color: #404a5a;
}

.table .thead-light th {
  color: #596069;
  background-color: #ebebeb;
  border-color: #d8d8d9;
}

.table-dark {
  color: #f1f3f5;
  background-color: #303844;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #404a5a;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.075);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1f61a3 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #174878 !important;
}

.text-secondary {
  color: #869098 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #6c777f !important;
}

.text-success {
  color: #d5f2ec !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #aee6da !important;
}

.text-info {
  color: #46b9f3 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #16a7f0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #e2000f !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #af000c !important;
}

.text-light {
  color: #ddd !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #c4c4c4 !important;
}

.text-dark {
  color: #34495e !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #22303d !important;
}

.text-body {
  color: #303844 !important;
}

.text-muted {
  color: #869098 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#header-page {
  background: #fff;
  box-shadow: 0 2px 4px rgba(87, 87, 86, 0.3);
  top: 0;
  z-index: 4;
}

.logo-area {
  display: inline-block;
}

.logo {
  width: 100px;
}

.logo,
.logo a,
.logo img {
  display: block;
}

.logo img {
  width: 100%;
  height: auto;
}

.slogan {
  color: #fff;
  font-size: 11px;
  line-height: 11px;
  padding: 0 0 0 16px;
  letter-spacing: 0.5px;
}

.slogan span {
  font-weight: 400;
  display: block;
  letter-spacing: -0.3px;
}

.nav-opener {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 15px;
}

.nav-opener:after,
.nav-opener:before,
.nav-opener span {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3px;
  background: #b6000f;
  transition: all 0.3s ease-in-out;
}

.nav-opener span {
  top: 6px;
}

.nav-opener:after {
  top: 12px;
}

.nav-active .nav-opener span {
  opacity: 0;
}

.nav-active .nav-opener:after,
.nav-active .nav-opener:before {
  top: 6px;
  transform: rotate(45deg);
}

.nav-active .nav-opener:after {
  transform: rotate(-45deg);
}

.selector {
  color: #fff;
  float: right;
  font-size: 14px;
  line-height: 18px;
  font-size: 18px;
  line-height: 18px;
  align-self: center;
}

.selector span {
  font-weight: 400;
  display: block;
}

.navigation {
  height: 50px;
  padding: 0 30px 0 0;
  position: relative;
}

#nav {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  border-top: 2px solid #ebebeb;
}

#nav li.active a {
  color: #e2000f;
}

#nav a {
  display: block;
  padding: 10px 15px;
  color: #303844;
  text-decoration: none;
}

#nav a:hover {
  color: #e2000f;
}

.user-list {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

.user-list li {
  border-top: 2px solid #ebebeb;
}

.user-list li.sub-list {
  position: relative;
  padding-right: 20px;
}

.user-list li.sub-list:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 10px;
  top: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #869098;
  transform: translateY(-50%);
}

.user-list li.sub-list .title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #869098;
  padding: 7px 32px !important;
}

.user-list li.sub-list ul {
  display: none;
}

.user-list li.sub-list ul.environments {
  display: block;
}

.user-list li.sub-list ul.environments li {
  background-color: #ebebeb !important;
  border-bottom: 1px solid #d8d8d9;
  text-align: left;
}

.user-list li.sub-list ul.environments li:hover {
  background-color: #fff !important;
}

.user-list li.sub-list ul.environments li a {
  padding: 8px 32px;
  font-size: 16px;
  color: #303844;
}

.user-list li.sub-list ul.environments li.active {
  background-color: #fff !important;
  position: relative;
}

.user-list li.sub-list ul.environments li.active:before {
  content: "\2713";
  color: green;
  position: absolute;
  font-size: 11px;
  height: 100%;
  left: 10px;
  display: flex;
  align-items: center;
}

.user-list li.sub-list:hover:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid #303844;
}

.user-list li.sub-list:hover > a {
  color: #303844;
}

.user-list li.sub-list:hover > ul {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
  padding-top: 40px;
  min-width: 276px;
}

.user-list li.sub-list:hover > ul:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
}

.user-list li.sub-list:hover > ul li {
  border-left: 0;
  background: #fff;
}

> .user-list li.sub-list:hover > ul li:last-child {
  padding-bottom: 15px;
}

> .user-list li.sub-list:hover > ul li:first-child {
  padding-top: 15px;
}

.user-list li.sub-list:hover > ul li > div,
.user-list li.sub-list:hover > ul li > p,
.user-list li.sub-list:hover > ul li > span {
  text-align: left;
}

.user-list li.sub-list:hover > ul li > a,
.user-list li.sub-list:hover > ul li > div,
.user-list li.sub-list:hover > ul li > p,
.user-list li.sub-list:hover > ul li > span {
  padding: 15px 32px;
  display: block;
}

.user-list li.sub-list:hover > ul li .display-name {
  padding: 32px 32px 16px !important;
}

.user-list li.sub-list:hover > ul li .options {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 0 32px 23px !important;
}

.user-list li.sub-list:hover > ul li .button,
.user-list li.sub-list:hover > ul li button {
  font-size: 14px;
}

.user-list li.sub-list:hover > ul li > ul {
  top: 0;
  left: -100%;
  border: none;
  width: auto;
  padding-top: 0;
  right: auto;
}

.user-list a {
  display: block;
  padding: 12px 15px;
  color: #869098;
  text-decoration: none;
}

.user-list a.language {
  font-size: 16px;
}

.user-list a.language span {
  position: relative;
  padding: 0 0 0 27px;
  display: inline-block;
  vertical-align: middle;
}

.user-list a.language span:before {
  font-weight: 400;
  content: "\e800";
  font-family: fontello;
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 24px;
  transform: translateY(-50%);
}

.user-list a:hover {
  color: #303844;
}

.search-form {
  padding: 5px 10px;
}

.search-form button[type="submit"] {
  float: right;
  height: auto;
  color: #869098;
  font-size: 18px;
  line-height: 20px;
  background: none;
  border: 0;
  padding: 0;
  margin: 6px 0 0;
}

.search-form button[type="submit"]:hover {
  color: #303844;
}

.search-form .input-holder {
  overflow: hidden;
}

.search-form input[type="search"] {
  width: 100%;
  border: 0;
  padding: 5px;
}

.cookie-legal .cookie-settings {
  display: none;
  padding: 16px 0 22px;
  background-color: #f1f3f5;
}

.cookie-legal .cookie-settings .consent-button-container {
  text-align: right;
}

.cookie-legal .cookie-explanation {
  padding: 40px 0;
}

.cookie-legal .collapse {
  transform: translateZ(0);
  display: block !important;
}

.cookie-legal p + h3 {
  padding-top: 18px;
}

.cookie-legal li {
  margin-bottom: 8px;
}

.cookie-legal li:last-child {
  margin-bottom: 0;
}

.cookie-legal .accordion {
  margin-top: 32px;
}

.cookie-legal .accordion .item {
  border: 1px solid #000;
  padding: 32px;
  margin-bottom: 32px;
}

.cookie-legal .accordion .item h5 {
  padding-bottom: 24px;
}

.cookie-legal .accordion .item h4 + div {
  padding-top: 24px;
}

.cookie-legal .accordion .item table {
  margin-top: 2px;
  border-collapse: collapse;
  width: 100%;
}

.cookie-legal .accordion .item table th {
  padding: 16px;
  text-align: left;
  color: #303844;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.cookie-legal .accordion .item table th:nth-child(n + 2):before {
  position: absolute;
  content: "";
  left: 0;
  height: 22px;
  border-right: 1px solid #ccd3d8;
}

.cookie-legal .accordion .item table tbody tr:nth-child(odd) td {
  background-color: #f1f3f5;
}

.cookie-legal .accordion .item table tbody td {
  padding: 16px;
  min-height: 80px;
  min-width: 80px;
  max-width: 230px;
  word-break: break-word;
}

.cookie-legal.footer-overlay {
  z-index: 1000;
  bottom: 0;
  overflow: auto;
  position: fixed;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
}

.cookie-legal.footer-overlay .cookie-settings {
  display: block !important;
}

.cookie-legal.footer-overlay:not(.open) .collapse {
  height: 0;
  transition: height 0.6s;
  overflow: hidden;
  padding: 0;
}

.cookie-legal.footer-overlay:not(.open) .cookie-toggle .closed {
  display: inline-block;
}

.cookie-legal.footer-overlay:not(.open) .cookie-toggle .open {
  display: none;
}

.cookie-legal.footer-overlay.open .collapse {
  height: 65vh;
  transition: height 0.6s;
  background-color: #fff;
}

.cookie-legal.footer-overlay.open .cookie-toggle .closed {
  display: none;
}

.cookie-legal.footer-overlay.open .cookie-toggle .open {
  display: inline-block;
}

.cookie-toggle {
  color: #e2000f;
}

.cookie-toggle .open {
  display: block;
}

.cookie-toggle > span {
  display: inline-block;
  min-width: 122px;
  position: relative;
  padding-right: 16px;
}

.cookie-toggle > span:before {
  right: 0;
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}

.cookie-toggle > span:after {
  right: 5.808px;
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.cookie-toggle > span:after,
.cookie-toggle > span:before {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  position: absolute;
  top: 50%;
  background: #e2000f;
}

.cookie-toggle > span .ui-accordion-header-active,
.open .cookie-toggle > span {
  border-bottom: none;
}

.cookie-toggle > span .ui-accordion-header-active:before,
.open .cookie-toggle > span:before {
  transform: rotate(45deg);
}

.cookie-toggle > span .ui-accordion-header-active:after,
.open .cookie-toggle > span:after {
  transform: rotate(-45deg);
}

body.cookies-open {
  height: 100vh;
  overflow: hidden;
}

.preview-video {
  min-height: 370px;
  height: 28.16901vw;
  position: relative;
  overflow: hidden;
  background: url(/ReactClient/dist/assets/baner_1723x490_10.png) no-repeat 40%;
  background-size: cover;
}

.preview-video .info-holder {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, transparent);
}

.preview-video .info-holder h1 {
  font-size: 43px;
  color: #fff;
  padding-bottom: 10px;
}

.preview-video .info-holder p {
  color: #fff;
  max-width: 510px;
}

.preview-video .info-holder .button {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding-left: 52px;
  position: relative;
  margin: 14px 0 0;
}

.preview-video .info-holder .button:after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 17px;
  left: 24px;
  border-top: 7px solid transparent;
  border-left: 12px solid #fff;
  border-bottom: 7px solid transparent;
}

#popup-player {
  min-height: 60vh;
  width: 100%;
}

#header-player {
  position: absolute;
  top: -25%;
  left: -50%;
  width: 200%;
  height: 56.33803vw;
  min-height: 370px;
  border: none;
  margin: auto;
}

.info {
  padding: 0 0 5px;
  transition: all 0.5s ease;
}

.info .information-block {
  text-align: center;
  position: relative;
  top: -57px;
  padding: 68px 45px 50px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.carousel {
  padding: 80px 0 20px;
  background: #f1f3f5;
}

.faq,
.news,
.videos,
.what-new {
  padding: 90px 0 80px;
  transition: all 0.5s ease;
}

.faq h2,
.news h2,
.videos h2 {
  font-size: 34px;
  padding-bottom: 30px;
}

.article_tile:hover h5 {
  color: #e2000f;
}

.news .info h5,
articles article_tile h5 {
  font-size: 18px !important;
  font-weight: bold !important;
}

.news .info p.news-body {
  font-family: MyriadProRegular, sans-serif !important;
  color: #596069 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  text-decoration: none !important;
  height: 400px !important;
}

.news .button {
  display: inline-block;
  margin-top: 2em;
}

.news .article_tile:hover {
  background: #f1f3f5;
}

.what-new {
  background: #f1f3f5;
}

.what-new h2 {
  font-size: 34px;
  padding-bottom: 0;
}

.what-new .update-note {
  padding-bottom: 20px;
}

.what-new .description h4 {
  font-size: 20px;
  padding-bottom: 20px;
}

.what-new .description ul {
  padding-bottom: 25px;
  padding-left: 0;
  margin: 0;
}

.what-new .description ul li {
  padding: 6px 0 6px 45px;
  background: url(/ReactClient/dist/assets/icon-circlecheck.svg) no-repeat 0 6px;
}

.videos .video-block {
  margin-bottom: 24px;
}

.videos .video-block a {
  color: #303844;
}

.videos .video-block h5 {
  margin: 0;
}

.videos .video-block img {
  width: 100%;
}

.videos .button {
  display: inline-block;
  margin-top: 15px;
}

.faq {
  background-color: #f1f3f5;
}

section.faq {
  padding: 90px 0 72px;
  background: #f1f3f5;
}

section.faq .inner-block {
  width: 855px;
}

section.faq h2 {
  font-size: 34px;
}

.accordion h5 {
  cursor: pointer;
  color: #596069;
  padding-top: 13px;
  padding-right: 30px;
  padding-bottom: 16px;
  position: relative;
  border-bottom: 1px solid #d8d8d9;
  outline: none;
  transition: color 0.5s ease;
  margin: 0;
  font-size: 18px;
}

.accordion h5:hover {
  color: #e2000f;
}

.accordion h5:after,
.accordion h5:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  position: absolute;
  top: 26px;
  background: #303844;
}

.accordion h4 {
  position: relative;
}

.accordion h4:hover {
  color: #e2000f;
}

.accordion h4:after,
.accordion h4:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  position: absolute;
  top: 13px;
  background: #303844;
}

.accordion .ui-state-focus {
  color: #303844;
}

.accordion h4:before,
.accordion h5:before {
  right: 0;
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}

.accordion h4:after,
.accordion h5:after {
  right: 6px;
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.accordion h4:hover:after,
.accordion h4:hover:before,
.accordion h5:hover:after,
.accordion h5:hover:before {
  background: #e2000f;
}

.accordion .ui-accordion-header-active {
  border-bottom: none;
}

.accordion .ui-accordion-header-active:before {
  transform: rotate(45deg);
}

.accordion .ui-accordion-header-active:after {
  transform: rotate(-45deg);
}

.accordion h5 + div {
  padding: 0 0 19px;
  border-bottom: 1px solid #d8d8d9;
}

.accordion h5 + div p {
  padding: 0 0 7px;
}

footer {
  background-color: #303844;
  bottom: 0;
  width: 100%;
  z-index: 1001;
}

footer .footer-links a {
  display: inline-block;
  margin-right: 30px;
  color: #fff;
}

.footer-global-site {
  color: #fff;
  position: relative;
}

.footer-global-site:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: -29px;
  background: url(/ReactClient/dist/assets/icon-globe.svg) no-repeat 0 0;
}

#DiagramContainer {
  overflow: hidden;
}

canvas#main {
  position: static !important;
  background: #fff;
}

.schema > #uig,
canvas#main,
canvas#move {
  width: 100% !important;
}

.schema > #uig {
  height: auto !important;
}

.intercom-launcher-discovery-frame,
.intercom-launcher-frame {
  margin-bottom: 50px !important;
  margin-right: 20px !important;
}

.modal-title {
  padding-top: 40px;
}

.modal-header .close {
  position: absolute;
  top: 40px;
  right: 35px;
  line-height: 5px;
}

.modal-footer {
  padding: 15px 100px;
  align-items: start;
  justify-content: flex-start;
}

.modal-footer:last-child {
  padding-bottom: 100px;
}

.header-white-line .sign-in {
  padding-left: 18px;
}

.header-white-line .popup.menu .favorites,
.header-white-line .popup.menu .user {
  display: block;
  position: relative;
  padding-left: 26px;
  background: url(/ReactClient/dist/assets/icon-star.svg) no-repeat 0 16px;
  border-left: none;
  border-top: 2px solid #ebebeb;
  border-bottom: 2px solid #ebebeb;
  text-align: left;
  border-right: none;
}

.header-white-line .popup.menu .favorites:before,
.header-white-line .popup.menu .user:before {
  display: block;
  content: "";
  width: 9px;
  height: 2px;
  position: absolute;
  top: 26px;
  right: 20px;
  background: #e2000f;
  transform: rotate(-45deg);
}

.header-white-line .popup.menu .favorites:after,
.header-white-line .popup.menu .user:after {
  border: none;
  display: block;
  content: "";
  width: 9px;
  height: 2px;
  position: absolute;
  right: 20px;
  top: 21px;
  background: #e2000f;
  transform: rotate(45deg);
}

.header-white-line .popup.menu .user {
  border-top: none;
  background: none;
  padding-left: 0;
}

.header-white-line .popup.menu.back {
  border-top: none !important;
  margin-left: 0;
  margin-bottom: 10px;
  padding-left: 30px !important;
}

.header-content {
  padding: 8px 10px;
  background: #b6000f;
  display: flex;
  flex-wrap: nowrap;
}

#global-search-input:focus {
  border: none;
}

#global-search-input {
  border: none;
  padding-left: 0px;
  align-self: center;
  height: 100%;
}

#global-search {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  display: flex;
  max-width: 300px;
  min-width: 150px;
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
  align-self: center;
  flex: 1 1 auto;
}

#global-search i {
  width: 50px;
  font-size: 20px;
  align-self: center;
  color: rgb(182, 0, 15);
  padding-left: 15px;
}

.logo-area {
  flex: 1 1 auto;
  display: flex;
}

.selector {
  font-size: 14px;
  line-height: 14px;
  flex: 1 1 auto;
}

.logo {
  align-self: center;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }

  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .modal-dialog {
    max-width: 676px;
    margin: 140px auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 280px);
  }

  .modal-sm {
    max-width: 300px;
  }

  .form-inline label {
    justify-content: center;
  }

  .form-inline .form-group,
  .form-inline label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    flex: 0 0 auto;
    flex-flow: row wrap;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }

  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .logo {
    width: 211px;
  }

  .nav-opener {
    display: none;
  }

  .navigation {
    justify-content: flex-end;
    padding: 0;
  }

  .nav-area .list-frame,
  .navigation {
    display: flex;
    flex-flow: row wrap;
  }

  #nav {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    border-top: 0;
    padding: 0 19px;
    border-left: 2px solid #ebebeb;
  }

  #nav li {
    margin: 0 15px;
  }

  #nav a {
    padding: 0;
  }

  .user-list {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: center;
  }

  .user-list li {
    border-top: 0;
    border-left: 2px solid #ebebeb;
  }

  .user-list a {
    min-width: 100px;
  }

  .search-form {
    flex-grow: 1;
    padding: 7px 28px;
  }

  .selector {
    font-size: 18px;
    line-height: 18px;
  }

  #global-search {
    height: 50px;
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }

  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .modal-lg {
    max-width: 1145px;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .header-content {
    padding: 8px 30px;
  }
}

@media (min-width: 1024px) {
  .search-form {
    padding: 7px 36px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }

  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media only screen and (max-width: 1250px) {
  .favorites {
    padding: 0 12px 0 20px;
    background: url(/ReactClient/dist/assets/icon-star.svg) no-repeat 0 16px;
  }

  .user {
    padding: 0 10px 0 5px;
  }

  .user:after {
    right: -10px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-white-line .desctop-navigation ul li {
    padding: 10px;
  }

  .header-white-line .desctop-navigation ul li a {
    line-height: 24px;
  }

  h2 {
    font-size: 18px;
  }

  .news-item img {
    width: 95%;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media only screen and (max-width: 992px) {
  .info .information-block {
    position: static;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media only screen and (max-width: 889px) {
  .header-white-line .desctop-navigation,
  .header-white-line .hide-with-all-menu {
    display: none;
  }

  .header-white-line form input[type="text"] {
    background: url(/ReactClient/dist/assets/icon-search.svg) no-repeat 5px 12px;
  }

  .header-white-line form input[type="text"]::-webkit-input-placeholder {
    color: #fff;
    opacity: 0;
  }

  .header-white-line form input[type="text"]:-moz-placeholder,
  .header-white-line form input[type="text"]::-moz-placeholder {
    color: #fff;
    opacity: 0;
  }

  .header-white-line form input[type="text"]:-ms-input-placeholder {
    color: #fff;
    opacity: 0;
  }

  .header-white-line .burger {
    display: inline-block;
    margin-left: 25px;
  }
}

@media (max-width: 768px) {
  #header-player {
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 56.33803vw;
    min-height: 370px;
    border: none;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .left-sign {
    display: none;
  }

  .owl-carousel .owl-item.active .item .info {
    -webkit-animation: a 0s forwards;
    -webkit-animation-delay: 0s;
    animation: a 0s forwards;
    animation-delay: 0s;
    right: 20px;
  }

  .container-narrow {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .nav-area .list-holder {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }

  .nav-active .nav-area .list-holder {
    padding: 0 0 15px;
    max-height: 4000px;
  }

  .nav-area .list-frame {
    background: #fff;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(87, 87, 86, 0.3);
  }

  .nav-active .nav-area .list-frame {
    transform: translateY(0);
  }

  .cookie-legal .accordion .item {
    padding: 20px 16px;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media only screen and (max-width: 480px) {
  .owl-carousel .owl-item.active .item .info {
    padding: 39px 20px 13px;
  }

  .what-new img {
    width: 100%;
  }

  footer {
    height: auto;
  }

  footer .footer-links a {
    margin-right: 15px;
  }

  .footer-links {
    padding-bottom: 24px;
    border-bottom: 1px solid #869098;
  }

  footer .text-right {
    text-align: left;
    padding-top: 24px;
  }

  .footer-global-site {
    margin-left: 28px;
  }
}

@media only screen and (max-width: 320px) {
  .popup.menu {
    width: 100%;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control,
  .modal.fade .modal-dialog {
    transition: none;
  }
}

/* Survey Notification Bar */

#SurveyNotificationBar {
  background: #7248b8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#SurveyNotificationBar p {
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
}

#SurveyNotificationBar a {
  text-decoration: none;
}

#SurveyNotificationBar i {
  margin: 0 5px;
}

#SurveyNotificationBar .title-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;
}

#SurveyNotificationBar .title-bar:hover {
  background: rgba(0, 0, 0, 0.25);
}

#SurveyNotificationBar .title-bar p {
  color: white;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 1.1rem;
  text-align: center;
  margin: 10px 75px;
}

#SurveyNotificationBar .title-bar a {
  color: white;
}

#SurveyNotificationBar .title-bar .expand-btn {
  padding-right: 25px;
}

#SurveyNotificationBar .details-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 50px 25px;
}

#SurveyNotificationBar .left-col {
  padding-right: 25px;
}

#SurveyNotificationBar img.survey-icon {
  width: 200px;
  max-width: 100%;
}
#SurveyNotificationBar img.survey-icon:hover {
  opacity: 0.75;
}

#SurveyNotificationBar .go-to-survey-btn {
  font-size: 2rem;
  margin: 1rem 0;
  color: #303844;
}

#SurveyNotificationBar .go-to-survey-btn:hover {
  opacity: 0.75;
}

#SurveyNotificationBar .right-col {
  min-height: 200px;
  min-width: 200px;
  max-width: 50vw;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

#SurveyNotificationBar a.hide-btn {
  color: #869098;
}

#SurveyNotificationBar a.hide-btn:hover {
  opacity: 0.75;
}

#ScrollContent {
  opacity: 0;
}

#SolutionContainer .tab-content > .active {
  display: flex;
}

.page-input > .container {
  padding-bottom: 40px;
}

.data-grid {
  padding-bottom: 40px;
}

.data-grid .searchbar {
  border: 0;
  background: transparent;
  margin-bottom: 0;
}

.data-grid .searchbar h2 {
  font-size: 24px;
  float: left;
  color: #303648;
  margin: 0;
  padding: 6px 0 0 0;
}

.data-grid .searchbar form {
  float: right;
  padding-bottom: 6px;
}

.data-grid .searchbar input {
  background: transparent;
  font-size: 18px;
}

.data-grid .searchbar .show-sidebar {
  float: right;
  position: relative;
  top: 6px;
  margin-right: 0;
  margin-left: 25px;
}

/* Depends on sidebar */
.with-sidebar .data-grid {
  width: 70%;
}

.with-left-sidebar .data-grid {
  /*float: right;*/
}

.with-right-sidebar .data-grid {
  float: left;
}

.with-right-sidebar .data-grid .searchbar form {
  margin-right: 25px;
}

.with-left-sidebar .data-grid .searchbar h2 {
  margin-left: 25px;
}

/* Table */
.data-grid .table {
}

.data-grid .table th {
  font-weight: normal;
  font-size: 14px;
  background: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  border-top: none;
}

.data-grid .table tbody th {
  font-weight: normal;
  font-size: 16px;
  background: #f1f3f5;
  padding: 16px 20px;
  color: #303648;
  border-bottom: 1px solid #ccc;
  border-top: none;
}

.data-grid .table td {
  font-size: 16px;
  color: #303648;
  padding: 16px 20px;
  border-top: none;
  border-bottom: 1px solid #ccc;
}

.data-grid .table td.favs {
  padding: 11px 8px 6px 8px;
  width: 1%;
  white-space: nowrap;
}

.data-grid .table a {
  color: #303648;
}

.data-grid .table a:hover {
  color: #e3200f;
}

.data-grid .table .fav {
  display: block;
  width: 30px;
  height: 30px;
  background: url(/ReactClient/dist/assets/icon_fav.png) no-repeat;
}

.data-grid .table .fav:hover,
.data-grid .table tr.favorite .fav,
.data-grid .table .fav.active {
  background: url(/ReactClient/dist/assets/icon_fav_active.png) no-repeat;
}

.data-grid .table-striped > tbody > tr:nth-of-type(odd),
tr.row-odd td {
  background-color: transparent;
}

.data-grid .table-striped > tbody > tr:nth-of-type(even),
tr.row-even td {
  background-color: #e1e4e8;
}

.data-grid .table-striped > tbody > tr:hover,
tr.row-hover {
  background-color: #edfdf9;
}

.data-grid .table-striped > tbody > tr:hover td,
tr.row-hover td {
  background-color: #edfdf9;
}

.data-grid .table-striped > tbody > tr:hover a {
  color: #e3200f;
  text-decoration: underline;
}

.with-left-sidebar .data-grid .table td.favs {
  padding-left: 25px;
}

/* ! Sidebar */
/*.data-grid-sidebar                               { float: right; width: 25%; z-index: 2 }*/
.data-grid-sidebar.overlayed {
  position: absolute;
  width: 25%;
  top: 0;
  right: 0;
  min-width: 330px;
  min-height: 100%;
  background: #303648;
}

.with-left-sidebar .data-grid-sidebar {
  float: left;
  width: 360px;
}

.data-grid-sidebar .panel {
  min-width: 100px;
}

.data-grid-sidebar.hiding {
  overflow: hidden;
  min-width: 0;
}

.data-grid-sidebar .panel-group .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
}

.data-grid-sidebar .panel-group .panel-heading {
  border: none;
  background: #303648;
  color: #fff;
  font-size: 14px;
  border-radius: 0;
  padding: 15px;
  border-bottom: 0.5px solid white;
}

.data-grid-sidebar .panel-group .sub-panel-heading {
  background: #869098;
}

.data-grid-sidebar .panel-group .panel-title {
  position: relative;
}

.data-grid-sidebar .panel-group .panel-heading a {
  display: block;
}

.data-grid-sidebar .panel-group .panel-body {
  border: none !important;
  padding: 20px 25px;
}

.data-grid-sidebar .form-control {
  font-size: 16px;
  color: #303648;
}

.data-grid-sidebar select.form-control {
  width: 100%;
  /*font-size: 12px;*/
  padding: 2px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.data-grid-sidebar hr {
  border-color: transparent;
}

.data-grid-sidebar .check-group {
  background: #f1f3f5;
  border-radius: 4px;
  padding: 1px 10px;
}

.data-grid-sidebar .check-group .checkbox {
  margin: 12px 0;
}

.data-grid-sidebar .check-group input {
  position: relative;
  top: -1px;
  margin-right: 8px;
}

/* Sidebar toggle */
.show-sidebar {
  display: none;
  white-space: nowrap;
  float: left;
  font-size: 14px;
  color: #fff !important;
  background: #303648;
  margin-right: 24px;
  padding: 5px 18px 4px 18px;
  text-decoration: none !important;
  border-radius: 14px;
}

.hide-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 55px;
  height: 100%;
  background: url(/ReactClient/dist/assets/icon_arrow_down.png) no-repeat 50% 50%;
  overflow: hidden;
  text-indent: -9999em;
}

.hide-sidebar:hover {
  background-color: rgba(200, 200, 200, 0.1);
}

.hide-sidebar.with-right-sidebar {
  background-image: url(/ReactClient/dist/assets/icon_arrow_down.png);
}

/* Folders */
ul.folders {
  list-style-type: none;
  padding: 0;
  margin: -15px -25px 0 -25px;
}

ul.folders li {
  padding: 0 25px;
  font-size: 16px;
  margin: 0;
  border-bottom: 1px solid #dadcdd;
  position: relative;
}

ul.folders li:hover {
  background: #f1f3f5;
}

ul.folders a {
  display: inline-block;
  white-space: nowrap;
  color: #303648;
  padding: 22px 0 22px 60px;
}

ul.folders a:hover {
  text-decoration: none;
  color: #e3200f;
}

ul.folders .icn-img,
ul.folders figure {
  position: absolute;
  top: 10px;
  left: 25px;
  display: inline-block;
  height: 48px;
  margin-right: 10px;
}

ul.folders figure img {
  display: block;
}

/* Actions */
ul.folders li .actions {
  display: none;
  position: absolute;
  top: 20px;
  right: 25px;
  white-space: nowrap;
}

ul.folders li:hover .actions {
  display: block;
}

ul.folders li .actions a {
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
}

/* Collapse summary */
.collapse-summary {
  text-align: center;
  padding: 22px 0 25px 0;
  background: #303648;
}

.panel-body .collapse-summary {
  margin: 0 -25px -19px -25px;
}

.collapse-summary p {
  font-weight: normal;
  margin-bottom: 10px;
  color: #fff;
}

.collapse-summary .btn {
  margin: 0 5px;
  padding: 5px 26px;
}

/* Uploader */
.uploader {
  background: #f1f3f5;
  padding: 20px;
  border-radius: 3px;
}

.uploader .dropzone {
  height: 220px;
  text-align: center;
  padding: 20px 0 10px 0;
}

.uploader .dropzone p {
  color: #7e818d;
  font-size: 18px;
  width: 150px;
  text-align: center;
  margin: 0 auto;
}

.uploader .dropzone figure {
  padding: 30px 0;
}

.uploader .nav-tabs > li {
  width: 50%;
  text-align: center;
}

.uploader .nav-tabs > li > a {
  width: 100%;
  display: inline-block;
}

.uploader .image-picker {
  height: 220px;
  text-align: center;
  padding: 20px 0 10px 0;
}

.uploader .image-picker p {
  color: #7e818d;
  font-size: 18px;
  width: 150px;
  text-align: center;
  margin: 0 auto;
  padding-top: 20px;
}

/* Tabs */
.nav-tabs > li > a {
  font-size: 14px;
  text-transform: uppercase;
  color: #a3a7b0;
  border: none;
  border-bottom: 2px solid #e1e4e8;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:active {
  border: none !important;
  border-bottom: 2px solid #e3200f !important;
  background: transparent !important;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:active {
  border: none;
  border-bottom: 2px solid #e3200f;
  background: transparent;
}

/* Report files */
.report-files {
}

.report-files .icn {
  position: relative;
  top: 2px;
  margin: 0 8px 0 10px;
}

.report-files .checkbox input[type="checkbox"] {
  position: relative;
  top: -1px;
}

.flang {
  margin: 0 -15px 16px 5px;
}

/* One list */
.one-list {
  padding: 25px 0;
}

.one-list h2 {
  font-size: 24px;
  color: #303648;
  margin: 0 0 24px 0;
  padding-top: 5px;
}

.one-list h2 a.backlist {
  float: left;
  width: 36px;
  height: 26px;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_back.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

.one-list p {
  font-size: 16px;
  margin-bottom: 25px;
}

.one-list .info {
}

.one-list .info h5 {
  font-size: 12px;
  margin: 0;
}

.one-list .info p {
  font-size: 16px;
  margin: 0;
}

.one-list .tools {
  list-style-type: none;
  margin: 0 30px 0 15px;
  padding: 0;
}

.one-list .tools li {
  margin: 0;
  padding: 0;
  font-size: 14px;
  border-bottom: 1px solid #d8dadc;
}

.one-list .tools li:last-child {
  border-bottom: none;
}

.one-list .tools a {
  color: #303648;
  display: block;
  padding: 10px 14px;
}

.one-list .tools a .glyphicon {
  color: #869098;
  margin-right: 8px;
}

.one-list .tools a:hover {
  text-decoration: none;
  color: #e3200f;
}

.one-list .tools a:hover .glyphicon {
  color: #e3200f;
}

/* Table with accordions */
.calculations .panel-group {
  margin: 0;
}

.calculations .panel-group .panel-heading {
  border: none;
  background: #fff;
  color: #303648;
  font-size: 14px;
  padding: 15px;
  border-bottom: 1px solid white;
}

.calculations .panel-group .panel-heading a {
  color: white;
  text-decoration: none;
}

.calculations .panel-group .panel-heading a:hover {
  color: #303648;
}

.calculations .panel-group .panel-heading .delete {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

a:hover,
a > span:hover {
  color: #303648;
}

.calculations .panel-heading .subtotal {
  margin-left: 50px;
  font-size: 16px;
  float: right;
  position: relative;
  top: 8px;
}

.calculations .panel-heading .subtotal em {
  color: #869098;
  font-style: normal;
  margin-left: 5px;
  font-size: 12px;
}

.calculations .panel-group .panel-body {
  border: none !important;
  padding: 0;
}

/* Results tables */

.table.table-results th {
  font-size: 14px;
  background: transparent;
  border-bottom: 1px solid #dbdee1;
}

.table.table-results td {
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}

.table.table-results a.delete {
  position: relative;
  top: 1px;
  margin-left: 5px;
}

.table.table-results .name {
  display: block;
  font-size: 14px;
  white-space: nowrap;
}

.table.table-results .num {
  display: block;
  font-size: 24px;
}

.table.table-results .code {
  display: block;
  font-size: 14px;
}

.table.table-results a.details {
  font-size: 14px;
  color: #a4b5bf;
  text-decoration: underline;
  margin-left: 12px;
}

.table.table-results a.details:hover {
  color: #e3200f;
}

.table.table-results .quantity {
  padding-top: 10px;
}

.table.table-results .quantity input {
  text-align: center;
}

.table.table-results .multipart .wrap {
  width: 85px;
  white-space: normal;
}

.table.table-results .multipart .wrap img {
  margin-bottom: 5px;
  margin-right: 2px;
}

.table.table-results .check-col {
  padding-left: 30px;
}

.table td.mark-success {
  background-color: rgba(46, 204, 113, 0.5) !important;
}

.table td.mark-warning {
  background-color: rgba(241, 196, 15, 0.5) !important;
}

.table td.mark-danger {
  background-color: rgba(231, 76, 60, 0.5) !important;
}

.table.table-striped > tbody > tr:nth-of-type(even) .bg-success {
  background-color: rgba(46, 204, 113, 0.5);
}

.table.table-striped > tbody > tr:nth-of-type(even) .bg-warning {
  background-color: rgba(241, 196, 15, 0.5);
}

.table.table-striped > tbody > tr:nth-of-type(even) .bg-danger {
  background-color: rgba(231, 76, 60, 0.5);
}

/* Table marks */
.table thead th.mark {
  padding: 0 !important;
  height: 5px;
  border-bottom: none;
  overflow: hidden;
  line-height: 5px;
  border-left: 1px solid #ccc;
}

.table thead tr th.mark:first-child {
  border-left: none;
}

.table td.mark,
.table th.mark {
  padding: 0;
  margin: 0;
  width: 5px;
}

.table tr.mark-success td.mark {
  background: #62ee70 !important;
}

.table tr.mark-warning td.mark {
  background: #facb81 !important;
}

.table tr.mark-danger td.mark {
  background: #fa8181 !important;
}

/* Results */
h2 {
  font-size: 24px;
  color: #303648;
  margin: 0;
}
a.backlist {
  width: 36px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_back.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin: 6px 10px 0px 0px;
}
a.forwardlist {
  width: 36px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_forward.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
span.true {
  width: 26px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_plus.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
span.false {
  width: 26px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_delete.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

h2 {
  font-size: 21px;
  color: #303648;
  margin: 0;
}

a.backlist {
  width: 36px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_back.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

a.forwardlist {
  width: 36px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_forward.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

span.true {
  width: 26px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_plus.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

span.false {
  width: 26px;
  height: 26px;
  float: left;
  background: #e1e4e8 url(/ReactClient/dist/assets/icon_delete.png) no-repeat 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  text-indent: -9999em;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

/* .with-left-sidebar .results-head h2    { margin-left: 25px; } */
.results-head .info-tools {
  position: absolute;
  top: 10px;
  right: 10px;
  white-space: nowrap;
  vertical-align: top;
}

.results-head .info-tools .btn-primary {
  background: #303648;
  margin-left: 8px;
  float: right;
}

.results-head .info-tools .products-selected-num {
  display: inline-block;
  height: 42px;
  line-height: 18px;
  font-size: 14px;
  color: #303648;
  overflow: hidden;
  margin: 0 22px 0 0;
  padding: 0;
  position: relative;
  top: 3px;
}

.results-head .info-tools .products-selected-num strong {
  font-weight: normal;
  line-height: 42px;
  float: left;
  font-size: 30px;
  margin-right: 8px;
}

.results-head .info-tools .show-sidebar {
  float: right;
  position: relative;
  top: 6px;
  margin-right: 0;
  margin-left: 25px;
}

/* Compare table */
.table.table-compare thead th {
  vertical-align: top;
  font-size: 16px;
  padding-top: 20px;
}

.table.table-compare th.product {
  border-left: 1px solid #ccc;
}

.table.table-compare tbody td {
  border-left: 1px solid #ccc;
}

.table.table-compare tbody tr:last-child td {
  border-bottom: none;
}

.table.table-compare tbody tr:last-child th {
  border-bottom: none;
}

.table.table-compare .product {
  padding-top: 10px;
}

.table.table-compare .product.selected {
  background-color: #d8fbdc;
}

.table.table-compare .product .wrap {
  position: relative;
  padding-top: 40px;
  padding-bottom: 5px;
}

.table.table-compare .product .remove-compare {
  position: absolute;
  top: 0;
  left: 0;
}

.table.table-compare .product .select-compare {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #303648;
  color: #303648;
  text-decoration: none;
  border-radius: 15px;
  padding: 4px 16px;
  line-height: 100%;
  font-size: 14px;
}

.table.table-compare .product.selected .select-compare {
  background: #2dd541;
  color: #fff;
  border-color: #2dd541;
}

.table.table-compare .product figure {
  float: left;
  margin-right: 5px;
  width: 95px;
}

.table.table-compare .product .name {
  font-size: 14px;
  display: block;
}

.table.table-compare .product .num {
  font-size: 24px;
  display: block;
}

.table.table-compare .product .code {
  font-size: 14px;
  display: block;
}

.table.table-compare .product .info {
  margin-left: 100px;
}

.table.table-compare .product .price {
  font-size: 18px;
  display: block;
  padding-top: 20px;
  float: left;
}

.table.table-compare .product .details {
  float: right;
  color: #869098;
  padding-top: 23px;
  font-size: 14px;
  text-decoration: underline;
}

.table.table-compare .product .details:hover {
  color: #e3200f;
}

/* Product details */
.page-details {
  background: #fff;
}

.page-details-bottom {
  background: transparent;
}

.product-details {
  margin-bottom: 14px;
  padding-top: 5px;
}

.product-details h2 {
  font-size: 36px;
  margin: 0 0 3px 0;
  line-height: 110%;
}

.product-details h3 {
  font-size: 24px;
  margin: 0;
  line-height: 110%;
}

.product-details h4 {
  font-size: 18px;
  margin: 0 0 5px 0;
  line-height: 130%;
}

.product-details h5 {
  font-size: 18px;
  margin: 0 0;
  line-height: 110%;
}

.product-details p {
  margin: 18px 0;
  font-size: 16px;
  line-height: 150%;
}

/* Summary */
.product-details .summary {
  background: #f1f3f5;
}

.product-details .summary .quantity {
  padding: 20px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.product-details .summary .quantity label {
  float: left;
  font-size: 18px;
  position: relative;
  top: 9px;
  margin-right: 12px;
}

.product-details .summary .quantity input[type="text"] {
  float: left;
  width: 40px;
  height: 42px;
  font-size: 16px;
  text-align: center;
}

.product-details .summary .add-to-list {
  padding: 10px 20px 10px 10px;
  border-top: 1px solid #e1e4e8;
}

.product-details .summary .add-to-list .btn-link {
  color: #e3200f;
}

/* Gallery */
.gallery {
}

.gallery .thumbs {
  float: left;
  width: 100px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.gallery .thumbs li {
  margin: 0 0 10px 0;
  padding: 0;
}

.gallery .thumbs a {
  display: block;
}

.gallery .thumbs img {
  border: 2px solid #eaeaec;
}

.gallery .thumbs .active img {
  border-color: #e3200f;
}

.gallery .preview {
  margin-left: 120px;
  text-align: center;
}

.gallery .preview img {
}

/* Tabs */
.page-details-bottom .product-detail-sections {
  margin-top: -43px;
}

.page-details .nav-tabs {
  background: #fff;
  border-bottom: none;
  margin-bottom: 25px;
}

.page-details .nav-tabs > li {
  margin-right: 10px;
}

.page-details .nav-tabs > li > a {
  font-size: 16px;
  text-transform: none;
  border-bottom-color: #fff;
  color: #303648;
}

.page-details .nav-tabs > li.active > a,
.page-details .nav-tabs > li.active > a:hover,
.page-details .nav-tabs > li.active > a:active {
  border-bottom: 3px solid #e3200f !important;
}

.page-details .table td {
  border: none;
}

.page-details .table-striped > tbody > tr:nth-of-type(even) {
  background: transparent;
}

.page-details .table-striped > tbody > tr:nth-of-type(odd) {
  background: #e1e4e8;
}

.page-details .table-striped > tbody > tr:hover {
  background: #fff;
}

/* Line group / timeline */
.linegroup {
  border-left: 1px solid #d6d8db;
  margin: -20px 0 -20px 10px;
  padding: 20px 0 20px 35px;
}

.linegroup .form-group {
  position: relative;
  margin-bottom: 20px;
}

.linegroup .icn-txt {
  position: absolute;
  top: 0;
  left: -48px;
}

/* Schema */
.schema {
  text-align: center;
  padding-top: 35px;
}

.schema-tools {
  padding: 20px 0 20px 25px;
}

.schema-tools .inset-nav {
  text-align: right;
}

.schema-tools .inset {
  background: #e0e3e6;
  padding: 3px;
  height: 40px;
  line-height: 40px;
  border-radius: 18px;
  box-shadow: inset 0 2px 2px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  text-align: center;
  margin-left: 14px;
}

.schema-tools .inset a {
  display: inline-block;
  color: #303648;
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
  padding: 0 18px;
  position: relative;
  top: -3px;
}

.schema-tools .inset a.active {
  background: #e3200f;
  color: #fff;
  text-decoration: none;
}

.schema-tools .inset a.active i {
  background-position: 0 -100px;
}

.schema-tools .inset a:hover {
  text-decoration: none;
}

.schema-tools .inset a .icn {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
}

.schema-tools .display-type a {
  padding: 0;
  width: 34px;
}

.expert-mode-toggle {
  font-size: 14px;
  color: #fff !important;
  background: #303648;
  border: 1px solid #303648;
  border-radius: 16px;
  display: inline-block;
  padding: 4px 16px;
  text-decoration: none !important;
}

.expert-mode-toggle.inactive {
  opacity: 0.5;
  background: transparent;
  color: #303648 !important;
}

.expert-mode-toggle:hover {
  color: #fff;
  text-decoration: none;
}

.expert-mode-toggle.inactive:hover {
  color: #e3200f !important;
  text-decoration: none;
}

@font-face {
  font-family: MyriadProRegular;
  font-weight: 400;
  font-style: normal;
  src: url(/ReactClient/dist/assets/MyriadProRegular.eot);
  src: url(/ReactClient/dist/assets/MyriadProRegular.eot?#iefix)
      format("embedded-opentype"),
    url(/ReactClient/dist/assets/MyriadProRegular.woff) format("woff"),
    url(/ReactClient/dist/assets/MyriadProRegular.ttf)
      format("truetype");
}

@font-face {
  font-family: MyriadProBold;
  font-weight: 700;
  font-style: normal;
  src: url(/ReactClient/dist/assets/MyriadProBold.eot);
  src: url(/ReactClient/dist/assets/MyriadProBold.eot?#iefix)
      format("embedded-opentype"),
    url(/ReactClient/dist/assets/MyriadProBold.woff) format("woff"),
    url(/ReactClient/dist/assets/MyriadProBold.ttf) format("truetype");
}

@font-face {
  font-family: fontello;
  font-weight: 400;
  font-style: normal;
  src: url(/ReactClient/dist/assets/fontello.eot);
  src: url(/ReactClient/dist/assets/fontello.eot?#iefix)
      format("embedded-opentype"),
    url(/ReactClient/dist/assets/fontello.woff) format("woff"),
    url(/ReactClient/dist/assets/fontello.ttf) format("truetype");
}

@font-face {
  font-family: "FontAwesome";
  src: url(/ReactClient/dist/assets/fontawesome-webfont.eot);
  src: url(/ReactClient/dist/assets/fontawesome-webfont.eot?#iefix&v=4.7.0)
      format("embedded-opentype"),
    url(/ReactClient/dist/assets/fontawesome-webfont.woff2) format("woff2"),
    url(/ReactClient/dist/assets/fontawesome-webfont.woff) format("woff"),
    url(/ReactClient/dist/assets/fontawesome-webfont.ttf)
      format("truetype"),
    url(/ReactClient/dist/assets/fontawesome-webfont.svg#fontawesomeregular)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

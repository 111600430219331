@media (min-width: 1200px) {
    /*.container { width: 1280px; }*/
}

@media (max-width: 1100px) {
    .grid-selection .item {
        width: 33.3333333%;
    }

    .grid-selection.selected .grid-selected {
        width: 33.3333333%;
        float: left;
    }

    .grid-selection.selected .grid {
        width: 66.6666666%;
        float: right;
    }

    .grid-selection.selected .no-favorites {
        width: 66.6666666%;
        float: right;
    }

    .grid-selection.selected .grid .item {
        width: 50%;
    }
}


@media (max-width: 1024px) {
    .with-sidebar .data-grid {
        width: 100%;
    }

    .show-sidebar {
        display: block;
    }
}


@media (max-width: 768px) {
    .hero {
        padding: 37px 15px 0 15px;
    }

    .searchbar .inset-nav {
        text-align: left;
        margin-top: 15px;
    }

    .searchbar .inset {
        margin: 0 15px 0 0;
    }

    .grid-selection .item {
        width: 50%;
    }

    .grid-selection.selected .grid-selected {
        width: 50%;
        float: left;
    }

    .grid-selection.selected .no-favorites {
        width: 50%;
        float: right;
    }

    .grid-selection.selected .grid {
        width: 50%;
        float: right;
    }

        .grid-selection.selected .grid .item {
            width: 100%;
            float: none;
        }

            .grid-selection.selected .grid .item .wrap {
                width: 100%;
                float: none;
                height: auto;
            }

    .feature-list .list {
        float: none;
        width: 100%;
    }

    .feature-list figure {
        float: none;
        width: 100%;
        margin-bottom: 35px;
    }

    .feature-overview-list .feature .txt {
        float: none !important;
        width: 100%;
        margin-bottom: 35px;
    }

    .feature-overview-list .feature figure {
        float: none !important;
        width: 90%;
        margin: 0 auto;
    }

    .testimonials li {
        width: 80%;
        display: block;
        margin: 0 auto 35px auto;
    }
}

@media (max-width: 480px) {
    .grid-selection .item {
        width: 100%;
        float: none;
    }

    .grid-selection.selected .grid-selected {
        width: 100%;
        float: none;
    }

    .grid-selection.selected .grid {
        width: 100%;
        float: none;
    }

    .grid-selection.selected .no-favorites {
        width: 100%;
        float: none;
    }

    .grid-selection.selected .grid .item {
        width: 100%;
        float: none;
    }

        .grid-selection.selected .grid .item .wrap {
            width: 100%;
            float: none;
            height: auto;
        }
}

@media (max-width: 420px) {
    #navbar .navbar-brand-selector {
        display: none;
    }
}

@media (max-width: 995px) {
    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: none;
        background: #D6170D;
        border: none;
        margin-left: -30px !important;
        margin-right: -30px !important;
        padding: 0 35px 25px 35px !important;
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

        .navbar-nav > li {
            float: none;
        }

            .navbar-nav > li > a {
                padding-top: 10px;
                padding-bottom: 10px;
            }

    .collapse.in {
        display: block !important;
    }

    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    #navbar .support-link {
        margin-left: 0px;
        margin-top: 10px;
    }

    #navbar .dropdown-menu {
        left: 0;
        min-width: auto;
        background-color: transparent;
        padding-left: 30px;
        padding-bottom: 20px;
    }

        #navbar .dropdown-menu a {
            color: #fff;
            height: auto;
            padding: 0 15px;
            margin: 4px 0;
            line-height: 120%;
        }

            #navbar .dropdown-menu a:hover {
                text-decoration: underline;
                background: transparent;
            }

    #navbar .lang-link {
        border-left: none;
        top: 0;
        margin-left: 10px;
        padding-left: 0;
        padding-top: 0px;
    }

    .hero-start .btn-video {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 40px;
    }

    .hero-start .txt {
        width: 100%;
    }
}

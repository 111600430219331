body {
  font-family: "Myriad Pro", sans-serif;
  padding-top: 0px;
  background-color: #f1f3f5;
}

.container {
  width: 100%;
  padding: 0 24px;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 0px;
}

p {
  color: #303648;
}

/* ! Buttons */
.btn {
  border-radius: 0;
  border: none;
  padding: 5px 18px;
  font-size: 16px;
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;
}

.btn-primary,
.btn-primary:active {
  background: #e71700 !important;
  border: 1px solid #e71700 !important;
  color: #fff !important;
}

.btn-primary:hover {
  background: #f93900 !important;
}

.btn-default {
  background: #fff;
  color: black;
}

.focus.btn-default,
.btn-default:focus,
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-secondary,
.btn-secondary:active {
  background: #464c5f !important;
  color: #fff;
}

.btn-secondary:hover {
  background: #575d6f !important;
  text-decoration: none;
}

.btn-white,
.btn-white:active {
  background: #fff !important;
  border: 1px solid #ccc !important;
  color: #303648 !important;
  margin-right: 10px;
}

.btn-white:hover {
  background: #fff !important;
  text-decoration: none;
}

.btn-info,
.btn-info:active {
  background: #303648 !important;
}

.btn-info:hover {
  background: #464c5f !important;
  text-decoration: none;
}

.btn .glyphicon {
  margin-right: 8px;
}

.toggle-unselected {
  background: #f1f3f5 !important;
  color: #303648;
}

/* Alerts */
.alert {
  font-size: 14px;
  border-radius: 3px;
  text-align: center;
}

.alert-danger {
  background: #e71700;
  border-color: #e71700;
  color: #fff;
}

/*NavBar*/
.navbar {
  height: 60px;
  background: #ca0f0a;
  border: none;
  display: block;
}

.navbar .navbar-brand {
  height: 100%;
  padding: 0;
  padding-top: 3px;
  float: left;
  width: 40%;
  overflow: hidden;
}

.navbar a {
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.navbar a.nav-link {
  color: #fff;
}

.navbar a.nav-link:hover {
  color: #000;
}

.navbar a i {
  font-size: larger;
  width: 20px;
}

.navbar img {
  height: 52px;
  margin-right: 20px;
}

.navbar .navbar-nav {
  display: block;
  float: right !important;
  width: 58%;
  height: 100%;
  overflow: no-content;
}

.navbar .navbar-nav .nav-divider {
  float: right;
  border-left: solid 1px #fff;
  opacity: 0.5;
  height: 40px;
  margin-top: 10px;
}

.navbar .navbar-nav .nav-item {
  float: right;
  display: inline-table;
  padding-top: 12px;
  padding-right: 6px;
}

.navbar .navbar-nav .nav-item.dropdown .dropdown-menu {
  top: 38px;
}

.navbar .dropdown-item {
  padding: 6px 6px 6px 8px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgb(245, 245, 245);
}

.navbar .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.navbar .dropdown-submenu .dropdown-menu {
  top: 0px !important;
  left: -100% !important;
  margin-left: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* ! Tables  */
td.s,
th.s {
  width: 1%;
  white-space: nowrap;
}

td.r,
th.r {
  text-align: right;
}

td.c,
th.c {
  text-align: right;
}

td.nw,
th.nw {
  white-space: nowrap;
}

.tablesaw-bar {
  position: relative;
  right: 20px;
}

/* ! Icons */
.icn {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 22px;
  height: 1.15em;
  background-repeat: no-repeat;
  background-size: contain;
}

.icn-img {
  position: relative;
  top: 2px;
  display: inline-block;
  line-height: 1;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
}

.icn-img {
  position: relative;
  top: 2px;
  display: inline-block;
  line-height: 1;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
}

.icn-lists {
  background-image: url(/ReactClient/dist/assets/icon_lists.png);
}

.icn-settings {
  background-image: url(/ReactClient/dist/assets/icon_settings.png);
}

.icn-logout {
  background-image: url(/ReactClient/dist/assets/icon_logout.png);
}

.icn-search {
  background-image: url(/ReactClient/dist/assets/icon_search.svg);
}

.icn-grid {
  background-image: url(/ReactClient/dist/assets/icon_grid.png);
}

.icn-back-alt {
  background-image: url(/ReactClient/dist/assets/icon_back_alt.png);
  width: 8px;
  height: 13px;
}

.icn-list {
  background-image: url(/ReactClient/dist/assets/icon_list.png);
}

.icn-list {
  background-image: url(/ReactClient/dist/assets/icon_list.png);
}

.icn-folder {
  background-image: url(/ReactClient/dist/assets/icon_folder.png);
  width: 48px;
  height: 38px;
}

.icn-folder-active {
  background-image: url(/ReactClient/dist/assets/icon_folder_active.png);
  width: 48px;
  height: 38px;
}

.icn-wrench {
  background-image: url(/ReactClient/dist/assets/icon_wrench.png);
  width: 20px;
  height: 20px;
}

.icn-pdf {
  background-image: url(/ReactClient/dist/assets/icon_pdf.png);
  width: 16px;
  height: 20px;
}

.icn-delete {
  background-image: url(/ReactClient/dist/assets/icon_delete.png);
  width: 20px;
  height: 20px;
}

.icn-star {
  background-image: url(/ReactClient/dist/assets/icon_star.png);
  width: 16px;
  height: 16px;
}

.icn-facebook {
  background-image: url(/ReactClient/dist/assets/icon_facebook.png);
  width: 32px;
  height: 32px;
}

.icn-twitter {
  background-image: url(/ReactClient/dist/assets/icon_twitter.png);
  width: 32px;
  height: 32px;
}

.icn-linkedin {
  background-image: url(/ReactClient/dist/assets/icon_linkedin.png);
  width: 32px;
  height: 32px;
}

.icn-play {
  background-image: url(/ReactClient/dist/assets/icon_play.png);
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.icn-heating {
  background-image: url(/ReactClient/dist/assets/icon_heating.png);
  width: 46px !important;
  height: 33px !important;
}

.icn-num {
  background: #575c6c;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 28px;
  border-radius: 50%;
  font-style: normal;
  margin-right: 16px;
  position: relative;
  top: -1px;
}

.icn-num.active {
  background-color: rgb(255, 0, 0);
}

.icn-txt {
  background: #ff0000;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-style: normal;
  margin-right: 16px;
  position: relative;
  top: -1px;
}

.icn-wrench-sm {
  background-image: url(/ReactClient/dist/assets/icon_wrench_sm.png);
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.icn-txt-danger {
  background: #ff0000;
}

.icn-txt-success {
  background: #07d949;
}

.icn-txt-danger-border {
  background: #fff;
  border: 2px solid #ff0000;
  width: 25px;
  height: 25px;
  color: #303648;
  line-height: 26px;
}

.icn-txt-success-border {
  background: #fff;
  border: 2px solid #07d949;
  width: 25px;
  height: 25px;
  color: #303648;
  line-height: 26px;
}

/* Modals */
.modal-content {
  border-radius: 3px;
}

.modal-header {
  border: none;
  border-bottom: 2px solid #f5f5f6;
  padding: 22px 0 10px 0;
  margin: 0 22px;
}

.modal-header .modal-title span {
  font-size: 24px;
}

.modal-header i {
  font-size: 28px;
}

.modal-footer {
  border: none;
  border-top: 2px solid #f5f5f6;
  padding: 16px 0px 4px 0px;
  margin: 0 22px;
  text-align: left;
}

.modal-body {
  padding: 22px;
}

.modal-dialog button.close {
  opacity: 0.4;
}

.modal-body .check-group {
  margin-bottom: 25px;
}

.modal-body .check-group label {
  font-size: 16px;
}

/* Forms */
.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group .ext {
  position: absolute;
  top: 34px;
  right: 10px;
  font-size: 14px;
  color: #303648;
  opacity: 0.3;
}

.form-group input.form-control {
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 3px !important;
}

.form-group select.form-control {
  height: 38px;
  line-height: 38px;
  background: #f1f3f5;
  border: none;
  box-shadow: none;
  padding: 8px 12px;
}

label {
  font-size: 14px;
  color: #303648;
  font-weight: normal;
  margin: 0;
}

.form-group .btn-side {
  position: relative;
  top: 24px;
  padding: 6px 18px;
  background: #869098 !important;
}

select:focus::-ms-value {
  background: #bdbdbd !important;
}
/* Breadcrumbs */
.breadcrumb {
  display: inline-block;
  background: inherit;
  /*box-shadow: 0 3px 1px -2px rgba(64,64,64,0.1);*/
  margin: 0;
  padding-top: 12px;
  padding-bottom: 4px;
  min-height: 42px;
  position: absolute;
  left: 0px;
}

.breadcrumb li.breadcrumb-item {
  margin-top: 0;
}

.breadcrumb li.breadcrumb-item:before {
  /*margin-top:12px;*/
  content: "/";
  /*font-size: 18px;*/
  /*font-size:x-large;*/
  font-weight: bold;
  color: #e1e4e8;
}

/*.breadcrumb li.breadcrumb-item:last-child a:after { 
        content: " X"; 
    }*/

.breadcrumb li.breadcrumb-item:first-child {
  margin-top: 0px;
  content: "" !important;
}

.breadcrumb li.breadcrumb-item:first-child:before {
  content: "";
}

.breadcrumb li.breadcrumb-item a {
  font-size: 16px;
  color: #303648;
  text-decoration: none;
  /*background-color:#e1e4e8;*/
  border-radius: 10px;
  padding: 2px 16px;
}

.breadcrumb li.breadcrumb-item a.fa-home {
  font-size: x-large;
  padding: 0px 2px;
  background-color: transparent;
}

.breadcrumb li.breadcrumb-item:last-child a {
  cursor: default;
}

.link-tools {
  height: 52px;
  line-height: 52px;
  font-size: 16px;
}

.link-tools a {
  color: #303648;
}

.link-tools i.icn-img {
  position: relative;
  top: 5px;
  margin-right: 6px;
}

/* Text page */
.page-text {
  padding: 30px 0 50px 0;
}

.page-text a.back {
  color: #e3200f;
  position: absolute;
  top: 50px;
  right: 100px;
  font-size: 16px;
}

.page-text a.back .icn-img {
  margin-right: 8px;
}

.text-box {
  width: 1024px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
  padding: 50px 100px 50px 100px;
}

.text-box h2 {
  font-size: 24px;
  margin: 0 0 25px 0;
  color: #303648;
}

.text-box p {
  font-size: 16px;
  margin: 0 0 20px 0;
  color: #303648;
}

.text-box ol {
  font-size: 16px;
  margin: 0 0 25px -20px;
  color: #303648;
}

.text-box li {
  margin: 0 0 20px 0;
  color: #303648;
}

/* Login / Signup */
.page-login {
  padding: 130px 0 50px 0;
}

.page-signup {
  padding: 70px 0 50px 0;
}

.form-box {
  width: 470px;
  box-sizing: border-box;
  padding: 25px;
  background: #fff;
  border-radius: 3px;
  margin: 0 auto;
}

.form-box label {
  display: block;
  font-size: 14px;
}

.form-box label a {
  color: #c1c2c8;
}

.form-box .checkbox a {
  color: #e3200f;
}

.form-box .form-control {
  font-size: 16px;
  padding: 8px 12px;
  height: 38px;
}

.form-box .checkbox label {
  font-size: 16px;
}

.form-box a {
  color: #e3200f;
}

.page-signup .form-box {
  width: 650px;
}

.form-box .form-header {
  border-bottom: 1px solid #ededef;
  font-size: 18px;
  color: #303648;
  padding-bottom: 20px;
}

.form-box .form-footer {
  border-top: 1px solid #ededef;
  font-size: 18px;
  color: #303648;
  padding-top: 25px;
  text-align: center;
}

.form-box .form-body {
  padding: 25px 0;
}

.form-box .form-actions {
  padding-top: 22px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.page-signup .form-box .form-actions {
  border-top: 1px solid #ededef;
  margin-top: 10px;
}

.has-success .form-control,
span.has-success {
  border-color: #0de828;
  background: #d8fbdc;
}

.has-error .form-control,
span.has-error {
  border-color: #ff0000;
  background: #fbd8d8;
}

.form-box .form-social-footer {
  text-align: left;
}

.form-box .form-social-footer .icons {
  position: relative;
  top: -10px;
}

.form-box .form-social-footer .icons a {
  margin-left: 4px;
}

/* ! Hero */
.hero {
  background: url(/ReactClient/dist/assets/hero.jpg) no-repeat 50% 50%;
  height: 245px;
  background-size: cover;
  color: #fff;
  padding: 37px 30px 0 30px;
  margin: 0;
  box-sizing: border-box;
}

.hero h2 {
  font-size: 34px;
  margin: 0 0 10px 0;
  font-weight: normal;
  max-width: 420px;
}

.hero h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  font-weight: normal;
  max-width: 420px;
}

.hero p {
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  max-width: 420px;
}

.subheader {
  margin: 18px 22px;
  display: block;
  border-bottom: 2px solid #dedfdf;
  height: 52px;
}

.subheader a {
  color: red;
  font-size: smaller;
  top: 132px;
  height: 24px;
  right: 32px;
  /*position: absolute;*/
}

.subheader h2 {
  font-size: 20px;
  padding-top: 4px;
  padding-bottom: 20px;
  width: 60%;
  display: block;
  float: left;
}

.container h3 {
  font-size: 16px;
}

/* ! Search bar */
.searchbar {
  background: #fff;
  border-bottom: 1px solid #dedfdf;
  min-height: 80px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 18px;
  margin: 0 0 45px 0;
}

/* ! Inset nav */
.searchbar .inset-nav {
  text-align: right;
}

.searchbar .inset {
  background: #f1f3f5;
  padding: 3px;
  height: 40px;
  line-height: 40px;
  border-radius: 18px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  text-align: center;
  margin-left: 14px;
}

.searchbar .inset a {
  display: inline-block;
  color: #303648;
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
  padding: 0 18px;
  position: relative;
  top: -3px;
}

.searchbar .inset a.active {
  background: #e3200f;
  color: #fff;
  text-decoration: none;
}

.searchbar .inset a.active i {
  background-position: 0 -100px;
}

.searchbar .inset a:hover {
  text-decoration: none;
}

.searchbar .inset a .icn {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
}

.searchbar .display-type a {
  padding: 0;
  width: 34px;
}

.searchbar .inset a .icn-img {
  margin-right: 3px;
  position: relative;
  top: 3px;
}

.searchbar .inset a.top .icn-img {
  background-position: 0 -100px;
}

.searchbar .inset a.top.active .icn-img {
  background-position: 0 0;
}

/* ! Search form */
.search-form {
  width: 320px;
  max-width: 320px;
  position: relative;
  padding: 2px;
  padding-left: 10px;
  display: block;
  float: right;
  margin-bottom: 8px;
  margin-right: 8px;
  /*width:38%;*/
  max-width: 800px;
}

.search-form i {
  font-size: small;
  margin-left: 6px;
  cursor: pointer;
  /*left: 30px;*/
  top: 13px;
  padding-bottom: 6px;
  padding-left: 17px;
  position: absolute;
}

.search-form input,
select.search {
  border: none;
  outline: none !important;
  box-shadow: 0 3px 1px -2px rgba(64, 64, 64, 0.1);
  border-radius: 2px;
  height: 38px;
}

.search-form input {
  padding-left: 24px;
}

.search-form input.empty {
  color: red;
}
/*.search-form a {
            left: 16px;
            top: 7px;
            padding-bottom: 6px;
            padding-left: 17px;
            position: absolute;
            cursor: pointer;
        }*/

/* ! Grid selection */
.page > .container {
  position: relative;
}

.grid-selection {
  margin-bottom: 45px;
}

.grid-selection h3 {
  font-size: 18px;
  color: #303648;
  margin: 0 0 16px 0;
  padding: 0 0 15px 0;
}

.grid-selection h3 em {
  font-style: normal;
  color: #a4b5bf;
  margin-left: 15px;
}

.grid-selection h3 a.helper {
  color: #90949e;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 15px;
  float: right;
}

.grid-selection ul {
  list-style-type: none;
  margin: 0 -5px;
  padding: 0;
}

.grid-selection .item {
  width: 20%;
  float: left;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
}

.grid-selection .item .wrap {
  display: block;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 15px;
  height: 150px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.grid-selection .item .wrap:hover {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.grid-selection .item h3 {
  font-size: 18px;
  color: #303648;
  margin: 0;
  height: 64px;
  overflow: hidden;
  line-height: 130%;
  backface-visibility: hidden;
}

.grid-selection .item figure {
}

.grid-selection .item figure img {
  max-width: 100%;
}

.grid-selection .item .fav {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background: url(/ReactClient/dist/assets/icon_fav.png) no-repeat;
}

.grid-selection .item .fav:hover,
.grid-selection .item .fav.active,
.grid-selection .item.favorite .fav {
  background: url(/ReactClient/dist/assets/icon_fav_active.png) no-repeat;
}

.grid-selection .item .xclose {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 0 16px 0 33px;
  font-size: 14px;
  color: #fff;
  border-radius: 14px;
  background: #303648 url(/ReactClient/dist/assets/icon_close.png) no-repeat 15px 50%;
}

.grid-selection .item .xclose:hover {
  text-decoration: none;
}

/* Toggles */
.grid-selection h3 .grid-toggle .opened-text {
  display: inline-block;
}

.grid-selection h3 .grid-toggle .closed-text {
  display: none;
}

.grid-selection.closed h3 .grid-toggle .opened-text,
.grid-selection.closing h3 .grid-toggle .opened-text {
  display: none;
}

.grid-selection.closed h3 .grid-toggle .closed-text,
.grid-selection.closing h3 .grid-toggle .closed-text {
  display: inline-block;
}

.grid-selection.closed h3 {
  border-bottom: 1px solid #dddfe3;
}

.grid-selection.opening h3 {
  border-bottom: 0;
}

.grid-selection.closed .grid-container {
  display: none;
}

.grid-selection.closed .helper,
.grid-selection.closing .helper {
  float: right;
}

.grid-selection .item-count {
  display: none;
}

.grid-selection.closed .item-count,
.grid-selection.closing .item-count {
  display: inline-block;
}

/* When selected */
.grid-selection.selected {
}

.grid-selection.selected .grid-selected .wrap {
  background: #e1e4e8;
}

.grid-selection.selected .grid-selected {
  width: 20%;
  float: left;
}

.grid-selection.selected .grid {
  width: 80%;
  float: right;
}

.grid-selection.selected .grid .item {
  width: 25%;
}

.grid-selection .grid.higher .item .wrap {
  height: 250px;
}

/* Favorites */
.grid-selection .no-favorites {
  display: none;
  padding-left: 10px;
  width: 80%;
  float: right;
  padding-bottom: 50px;
}

.grid-selection.show-favorites-only .grid .item {
  display: none;
}

.grid-selection.show-favorites-only .grid .item.favorite {
  display: block;
}

/* Starting page */
.page-web {
  margin: 0 auto;
}

/* Hero */
.hero-start {
  /*background: url(../../assets/img/hero_start.jpg) no-repeat 50% 50%;*/
  background-size: cover;
  margin: 0 auto;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  padding: 0;
}

.hero-start .container {
  height: 700px;
  padding: 37px 30px 0 30px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.hero-start h2 {
  color: #303648;
  font-size: 32px;
  margin: 0 0 20px 0;
  font-weight: 100;
}

.hero-start h2 strong {
  font-weight: bold;
}

.hero-start p {
  font-size: 20px;
  margin: 0 0 15px 0;
}

.hero-start .btn {
  font-size: 18px;
}

.hero-start .txt {
  width: 500px;
  padding-top: 60px;
}

.hero-start .actions {
  margin: 0 0 55px 0;
}

.hero-start .actions .btn {
  margin-right: 20px;
}

.hero-start .actions .btn-default {
  background: transparent;
  border: 1px solid #303648;
}

.hero-start .teaser {
  border: 3px solid #de2400;
  width: 140px;
  padding: 12px;
  box-sizing: border-box;
  color: #de2400;
  font-size: 13px;
}

.hero-start .teaser em {
  font-size: 36px;
  font-style: normal;
  display: block;
  font-weight: bold;
}

.hero-start a {
  padding: 12px;
  color: #de2400;
  font-size: 36px;
}

.hero-start .btn-video {
  position: absolute;
  top: 265px;
  right: 175px;
}

/* Feature list */
.feature-list {
  width: 100%;
  overflow: hidden;
  background: #fff;
}

.feature-list .container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 80px 50px;
  box-sizing: border-box;
}

.feature-list .list {
  float: right;
  width: 43%;
  padding-left: 25px;
  box-sizing: border-box;
}

.feature-list figure {
  float: left;
  width: 57%;
  padding-right: 25px;
  box-sizing: border-box;
}

.feature-list figure img {
  width: 100%;
}

.feature-list h3 {
  font-size: 32px;
  font-weight: 100;
  margin: 0 0 35px 0;
}

.feature-list h3 strong {
  font-weight: bold;
}

.feature-list ul {
  list-style-type: none;
  font-size: 22px;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.feature-list li {
  background: url(/ReactClient/dist/assets/icon_plus.png) no-repeat 0 4px;
  padding: 0 0 10px 32px;
}

/* Feature overview */
.feature-overview-list {
  background: #a4b5bf;
}

.feature-overview-list .container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 80px 0;
  position: relative;
}

.feature-overview-list ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.feature-overview-list .feature {
  overflow: hidden;
  padding: 80px 0 0 0;
}

.feature-overview-list .feature .txt {
  float: right;
  width: 38%;
  box-sizing: border-box;
  padding: 12px 80px 0 170px;
  position: relative;
}

.feature-overview-list .feature figure {
  float: left;
  width: 62%;
  box-sizing: border-box;
}

.feature-overview-list .feature.figr .txt {
  float: left;
  padding: 12px 80px 0 170px;
}

.feature-overview-list .feature.figr figure {
  float: right;
}

.feature-overview-list .feature figure img {
  width: 100%;
}

.feature-overview-list .feature .index {
  position: absolute;
  top: 0;
  left: 80px;
  font-size: 32px;
  color: #fff;
  background: #b2c4ce;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 30px;
  font-style: normal;
  text-indent: 5px;
}

.feature-overview-list .feature h3 {
  font-size: 32px;
  color: #fff;
  margin: 0 0 10px 0;
  font-weight: 100;
}

.feature-overview-list .feature p {
  font-size: 20px;
  color: #fff;
  font-weight: 100;
}

/* Signup */
.signup {
  background: #303648;
  color: #fff;
}

.signup .container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 75px 45px 75px;
  box-sizing: border-box;
}

.signup h2 {
  font-size: 30px;
}

.signup form {
  margin: 0;
  padding: 0;
}

.signup .form-group {
  position: relative;
  top: 12px;
}

.signup .form-control {
  height: 40px;
  width: 105%;
}

.signup .form-actions {
  position: relative;
  top: 9px;
}

.signup .btn {
}

/* Testimonials */
.testimonials {
  background: #fff;
  text-align: center;
}

.testimonials .container {
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 60px 0;
}

.testimonials h2 {
  font-size: 40px;
  font-weight: 100;
  margin: 0 0 50px 0;
}

.testimonials h3 {
  font-size: 20px;
  margin: 0 0 5px 0;
}

.testimonials h4 {
  font-size: 14px;
  margin: 0 0 12px 0;
}

.testimonials p {
  font-size: 14px;
  margin: 0;
}

.testimonials figure {
  margin: 0 0 26px 0;
}

.testimonials ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.testimonials li {
  width: 20%;
  display: inline-block;
  margin: 0;
  padding: 0 30px;
  vertical-align: top;
}

/* Footer */
#f1 {
  background: #303648;
  color: #fff;
  padding: 20px;
  position: relative;
}

#f1 .container {
  position: relative;
}

#f1 .copy {
  color: #fff;
  position: relative;
  top: 5px;
}

#f1 .nav {
  position: absolute;
  top: -10px;
  right: 30px;
  z-index: 10;
}

#f1 .dropdown-toggle {
  color: #fff;
  background: transparent !important;
}

#product_filters .filter-field {
  margin: 0;
  display: inline-block;
  width: 49%;
  font-size: 16px;
}

#navigation a {
  cursor: pointer;
  text-decoration: none;
}

.form-control::-ms-clear {
  display: none;
}

a.list-group-item,
button.list-group-item {
  color: #555;
  text-decoration: none;
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: #f5f5f5;
}

button.subgroup {
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding: 2px 10px 0px;
  margin: 1px;
  opacity: 1;
  margin-bottom: 10px;
  color: rgb(48, 54, 72);
  border-top-color: rgb(241, 243, 245);
  border-right-color: rgb(241, 243, 245);
  border-bottom-color: rgb(241, 243, 245);
  border-left-color: rgb(241, 243, 245);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-image-source: none;
  border-image-slice: 100%;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: stretch;
  background-color: rgb(241, 243, 245);
  cursor: pointer;
}

button.subgroup:hover {
  color: #8a3636;
  border: 1px solid #8a3636;
}

button.subgroup.active {
  color: rgb(208, 2, 27);
  border: 1px solid rgb(227, 32, 15);
  background-color: rgb(255, 255, 255);
}

button.param,
button.param:hover,
button.param:focus {
  background: rgb(241, 243, 245);
  margin: 1px;
  padding: 2px 10px 0px;
  border-radius: 3px;
  border: 1px solid rgb(241, 243, 245);
  border-image: none;
  text-align: center;
  color: rgb(48, 54, 72);
  line-height: 28px;
  font-size: 16px;
  opacity: 1;
  cursor: pointer;
}

/*button.param:hover {
        color: #8a3636;
        border: 1px solid #8a3636;
    }*/

button.param.active {
  color: rgb(208, 2, 27);
  border: 1px solid rgb(227, 32, 15);
  background-color: rgb(255, 255, 255);
}

button.param:disabled {
  opacity: 0.19;
  background-color: #f1f3f5;
  color: #303648;
  border-color: #f1f3f5;
}

button.question {
  border-image: none;
  line-height: 22px;
  border-radius: 0;
  padding: 5px 18px;
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
  opacity: 1;
  cursor: pointer;
  background: #fff !important;
  border: 1px solid #ccc !important;
  color: #303648 !important;
}

button.question:hover {
  background: #fff !important;
  text-decoration: none;
}

button.question.active {
  background: #e71700 !important;
  border: 1px solid #e71700 !important;
  color: #fff !important;
}

button.question.active:hover {
  background: #f93900 !important;
  text-decoration: none;
}

button.question:disabled {
  opacity: 0.19;
  background-color: #f1f3f5;
  color: #303648;
  border-color: #f1f3f5;
}

mark {
  padding: 0;
}

.table-filter {
  background-color: #fff !important;
}

.table-filter :hover {
  background-color: #fff !important;
}

.info-icon {
  color: #ccc;
  cursor: default;
  padding-right: 2px;
  padding-left: 5px;
}

.info-icon :hover {
  color: #777;
}

.tool-description {
  width: 187.6px;
  color: #e01f26;
  white-space: pre-wrap;
  position: absolute;
  font-size: 10pt;
  padding: 3px;
  display: block;
  text-align: center;
}

.tool-settings {
  white-space: nowrap;
  position: absolute;
  text-align: right;
  border: 1px solid #bdbdbd !important;
  box-shadow: 2px 2px 7px -2px rgba(0, 0, 0, 0.6);
  width: 84px;
}

.tool-settings .input-title {
  background: #fff;
  color: #000;
  padding: 0px 2px 0px 4px;
  text-align: left;
  border-bottom: 1px solid #bdbdbd;
}

.tool-settings .input-title span {
  font-size: smaller;
}

.tool-settings .input-title span.icon {
  display: inline-block;
  padding-top: 3px;
}

.tool-settings .input {
  text-align: left;
  margin-bottom: -3px;
}

.tool-settings.tool-disabled .input {
  background-color: rgb(240, 240, 240);
}

.tool-settings .input input {
  display: inline-block;
  padding: 1px 6px 3px;
  text-align: left;
  border: 0;
  width: 100%;
  vertical-align: text-bottom;
  background-color: transparent;
}

.tool-settings.tool-disabled.red div.input {
  background-color: rgb(224, 31, 38);
}

.tool-settings.tool-disabled.red div.input input {
  opacity: 0.6;
}

.tool-settings .input select {
  color: inherit;
  background-color: transparent;
  padding: 0px 0px 4px 4px;
  border: 0;
}

.tool-settings .input select option:focus {
  color: black;
  background-color: white;
}

.tool-settings.tool-select select {
  width: 100%;
  background-color: transparent;
  padding-left: 2px;
  padding-right: 0px;
  padding-top: 2px;
  padding-bottom: 3px;
  border: 0;
  display: inline;
}

.tool-settings.tool-input {
  position: absolute;
  border: 3px solid rgb(42, 219, 42);
  border-image: none;
  text-align: left;
  color: rgb(102, 102, 102);
  display: block;
  background-color: rgb(255, 255, 255);
}

.tool-settings.tool-input select option {
  color: black !important;
}

.tool-settings.tool-input.green div.input {
  background-color: rgb(42, 219, 42);
}

.tool-settings.tool-input.green div.input input {
  color: white;
}

.tool-settings.tool-input.green div.input select {
  color: white;
}

.tool-settings.tool-input.red div.input {
  background-color: rgb(224, 31, 38);
}

.tool-settings.tool-input.red div.input input {
  color: white;
}

.tool-settings.tool-input.red div.input select {
  color: white;
}

.tool-settings .input-footer table {
  border-top: 1px solid #bdbdbd;
  width: 100%;
  font-size: x-small;
  background-color: #fff;
  color: #000;
}

.tool-settings .input-footer table td {
  padding: 0;
  text-align: center;
}

.tool-settings .input-footer table td:first-child {
  border-right: solid 1px #bdbdbd;
}

.data-grid-container .top {
  border-top: 1px solid #dedede;
  margin-top: 40px;
}

.gallery .thumbs li {
  margin: 0px;
}

.gallery .thumbs div {
  margin: 5px;
  padding: 2px;
  background-color: #eaeaec;
}

.gallery .thumbs div:hover {
  background-color: #eaeaec;
}

.gallery .thumbs .active div {
  background-color: #e3200f;
}

tr span.checkbox {
  color: white;
  background: white;
  cursor: pointer;
  padding: 3px;
  border: solid 1px #d4d4d4;
  background-color: white;
  border-radius: 5px;
  height: 25px;
  width: 25px;
}

tr.active span.checkbox,
tr span.checkbox.active {
  color: white;
  background: red;
}

input.amount {
  text-align: center;
  padding: 1px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  max-width: 50px;
  display: inline;
  font-size: 13px;
  height: 25px;
}

.help-area {
  margin-bottom: 5px;
}

.video-area .caption {
  padding: 0;
  height: 42px;
  overflow: hidden;
}

.video-area .caption .title {
  font-size: 16px;
  color: #303648;
}

li.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu > li > a {
  padding: 3px;
  padding-left: 8px;
  font-size: 14px;
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4285;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover {
  background-color: rgb(245, 245, 245);
}

.show > .dropdown-menu.flex {
  display: flex;
}

h4,
h5 {
  letter-spacing: 0px;
  font-size: 16px;
  color: inherit;
}

h6 {
  letter-spacing: 0px;
  font-size: 12px;
  color: inherit;
}

.icn-settings-sm {
  background-image: url(/ReactClient/dist/assets/icon_settings_sm.png);
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.btn-white,
.btn-white:active {
  margin-right: 0;
}

.hero {
  background: url(/ReactClient/dist/assets/hero.jpg) no-repeat 50% 50%;
  height: 367px;
  background-size: cover;
  color: #fff;
  padding: 37px 30px 0 30px;
  margin: 0;
  box-sizing: border-box;
}

.grid-selection .item h3 {
  font-size: 18px;
  color: #303648;
  margin: 0;
  height: 70px;
  overflow: hidden;
  line-height: 130%;
  backface-visibility: hidden;
}

.table-compare tr.diff td {
  font-weight: bolder;
}

.form-details {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px white;
  box-sizing: border-box;
  padding: 25px;
  background: #fff;
  border-radius: 1px;
  margin: 0 auto;
}

.form-details label {
  display: block;
  font-size: 14px;
}

.form-panel {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px white;
  box-sizing: border-box;
  padding: 25px;
  background: #fff;
  border-radius: 1px;
  margin: 0 auto;
}

.form-panel:hover {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px white;
  text-decoration: underline;
}

.form-panel label {
  display: block;
  font-size: 14px;
}

.form-panel label a {
  color: #c1c2c8;
}

.form-panel .checkbox a {
  color: #e3200f;
}

.form-panel .form-control {
  font-size: 16px;
  padding: 8px 12px;
  height: 38px;
}

.form-panel .checkbox label {
  font-size: 16px;
}

.form-panel .form-header {
  border-bottom: 1px solid #ededef;
  font-size: 18px;
  color: #303648;
  padding-bottom: 20px;
  display: flex;
}

.form-panel .form-footer {
  border-top: 1px solid #ededef;
  font-size: 18px;
  color: #303648;
  padding-top: 25px;
  text-align: center;
}

.form-panel .form-body {
  padding: 25px 0;
}

.form-panel .form-actions {
  padding-top: 22px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.form-panel .has-success .form-control,
div.with-error div.has-success {
  border-color: #0de828;
  background: #d8fbdc;
}

.form-panel .has-error .form-control,
div.with-error div.has-error {
  border-color: #ff0000;
  background: #fbd8d8;
}

.form-panel .form-social-footer {
  text-align: left;
}

.form-panel .form-social-footer .icons {
  position: relative;
  top: -10px;
}

.form-panel .form-social-footer .icons a {
  margin-left: 4px;
}

.trend {
  color: grey;
}

.trend.positive {
  color: green;
}

.trend.negative {
  color: red;
}

.current {
  font-size: x-large;
}

.schema {
  text-align: left;
  padding-top: 0;
}

.data-grid-sidebar .panel-group .panel-heading a {
  color: inherit;
  display: inline;
}

.data-grid-sidebar .panel-group .panel-heading a:focus,
.data-grid-sidebar .panel-group .panel-heading a:hover {
  text-decoration: none;
  font-weight: bold;
}

.data-grid-sidebar .panel-group .panel-body {
  border: none !important;
}

.data-grid-sidebar .description {
  padding: 3px 3px 3px 8px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-size: 12pt;
  margin-top: 8px;
  margin-bottom: 5px;
  display: block;
  background-color: rgb(48, 54, 72);
}

.collapse-summary {
  text-align: center;
  padding: 10px 0 15px 0;
  background: #303648;
}

#product_filters .filter-field {
  margin: 0;
  display: inline-block;
  width: 99%;
  font-size: 16px;
}

table.folders tbody tr td {
  padding: 4px;
  border-top: 0px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

table.folders > tbody > tr.table-active > td {
  background-color: #f5f5f5;
}

table.folders tbody tr:hover td {
  color: #e3200f;
}

table.folders tbody tr td a {
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  color: #303648;
}

table.folders tbody tr td.folder-icon {
  padding-left: 24px;
  width: 60px;
  text-align: center;
  vertical-align: bottom;
}

table.folders tbody tr td.folder-icon i.fa-folder-open {
  color: #e71700;
  font-size: 42px;
  position: relative;
  left: -8px;
  cursor: pointer;
}

table tbody tr td.folder-name {
  font-size: 16px;
}

div.actions {
  width: 100%;
}

table.folders tbody tr td.folder-actions {
  width: 30px;
}

li div.actions {
  position: absolute;
}

td div.actions {
  display: flex;
}

div.actions a {
  font-size: 19px;
  display: none;
  padding-right: 4px;
  padding-right: 4px;
}

tr:hover td div.actions a,
div.actions:hover a,
li:hover div.actions a,
tr.row-hover td div.actions a {
  display: flex;
}

div.actions a {
  color: darkslategray !important;
}

div.actions a:hover {
  color: red !important;
}

table.folders tbody tr td .btn {
  padding: 3px;
  padding-left: 5px;
  padding-right: 3px;
  margin-top: 3px;
  margin-bottom: 2px;
  background: #ffffff;
  border: 1px solid #e3200f;
  color: #d0021b;
  display: inline-block;
  white-space: nowrap;
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

table.folders tbody tr td input {
  background-color: #ffffff !important;
}

table.folders tbody tr td ul.dropdown-menu {
  min-width: 48px;
  padding: 8px;
  background-color: white;
}

#CalculationList i {
  font-size: 16px;
  cursor: pointer;
}

i.fa:hover {
  text-decoration: none;
}

.data-grid-sidebar .panel-group .panel-heading .stepadd-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 55px;
  height: 100%;
  background: url(/ReactClient/dist/assets/icon_plus.png) no-repeat 50% 50%;
  overflow: hidden;
  text-indent: -9999em;
}

.data-grid-sidebar .panel-group .panel-heading .stepadd-sidebar:hover {
  background-color: rgba(200, 200, 200, 0.1);
}

.data-grid-sidebar .panel-group .panel-heading .stepdel-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 55px;
  height: 100%;
  background: url(/ReactClient/dist/assets/icon_delete.png) no-repeat 50% 50%;
  overflow: hidden;
  text-indent: -9999em;
}

.data-grid-sidebar .panel-group .panel-heading .stepdel-sidebar:hover {
  background-color: rgba(200, 200, 200, 0.1);
}

.data-grid-container h3 {
  font-size: 24px;
}

.data-grid-container h2 {
  font-size: 24px;
}

.data-grid-container h5 {
  font-size: 14px;
}

h3.modal-title {
  font-size: 18px;
}

.editor {
  overflow: auto;
  max-height: 300px;
  background-color: #fff;
  border: solid 1px #aaa;
}

#UploadWizard .selected {
  background-color: #1f75cc;
  color: white;
  z-index: 100;
}

#UploadWizard .messagepop {
  background-color: #ffffff;
  border: 1px solid #999999;
  cursor: default;
  display: none;
  margin-top: 15px;
  position: absolute;
  text-align: left;
  width: 450px;
  z-index: 50;
  padding: 25px 25px 20px;
}

#UploadWizard label {
  display: block;
  margin-bottom: 3px;
  padding-left: 15px;
  text-indent: -15px;
  display: inline;
}

#UploadWizard .messagepop p,
.messagepop.div {
  border-bottom: 1px solid #efefef;
  margin: 8px 0;
  padding-bottom: 8px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Myriad Pro", sans-serif;
}

/* Help areas */
.help-area {
  margin-bottom: 55px;
}

.help-area header {
  border-bottom: 1px solid #dddfe3;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  display: block;
  height: 40px;
}

.help-area header h2 {
  width: 90%;
  font-size: 24px;
  color: #303648;
  display: block;
  float: left;
}

.help-area header .tabs {
  position: absolute;
  top: 0;
  right: 0;
}

.help-area header a,
.help-area .more {
  float: right;
  margin-top: 8px;
  color: #90949e;
  font-size: 14px;
  text-decoration: underline;
}
/* Videos */
.video-area {
}

.video-area .tabs {
  margin: 0;
  list-style-type: none;
  top: 4px !important;
}

.video-area .tabs li {
  float: right;
  margin: 0 12px;
}

.video-area .tabs li > a {
  font-size: 16px;
  padding: 12px 8px 22px 8px;
  text-transform: uppercase;
  color: #a3a7b0;
  border: none;
  border-bottom: 2px solid transparent;
  text-decoration: none !important;
}

.video-area .tabs li.active > a,
.video-area .tabs li.active > a:hover,
.nav-tabs > li.active > a:active {
  border: none !important;
  border-bottom: 2px solid #e3200f !important;
  background: transparent !important;
  text-decoration: none !important;
}

.video-area .tabs li > a:hover,
.nav-tabs > li > a:active {
  border: none;
  border-bottom: 2px solid #e3200f;
  background: transparent;
}

.video-area .video-item {
  margin-bottom: 20px;
}

.video-area .video {
  margin-bottom: 10px;
}

.video-area .video img {
  max-width: 100%;
  width: 100%;
}

.video-area .caption {
  padding: 0;
  height: 21px;
  overflow: hidden;
}

.video-area .caption .title {
  font-size: 16px;
  color: #303648;
}

.video-area .caption .timestamp {
  color: #a4b5bf;
  font-size: 16px;
}

/* Articles */
.news-area {
}

.news-area article {
  margin-bottom: 25px;
  /*text-align: center;*/
}

.news-are figure {
  margin-bottom: 15px;
}

.news-area article img {
  max-width: 100%;
  width: 100%;
}

.news-area article h3 {
  font-size: 20px;
  /*background: url(../../assets/img/icon_arow_right.png) no-repeat right center;*/
  margin: 0 0 6px 0;
}

.news-area article h3 a {
  color: #303648;
}

.news-area article p {
  color: #303648;
  font-size: 16px;
  margin: 0;
}

.news-area article p.when {
  color: #a4b5bf;
  font-size: 16px;
  margin: 0 0 8px 0;
}

.news-area article h3 {
  font-size: 20px;
  background: none;
  margin: 0 0 6px 0;
}

.article_tile {
  cursor: pointer;
  padding: 15px;
  padding-top: 0px;
}

.article_tile:hover {
  background: white;
}

.article_tile .img_container {
  height: 200px;
  margin: 15px 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}

.article_title {
  /*height: 53px;*/
  overflow: hidden;
  margin: 1em 0;
  margin-top: 2em;
}

.article_title h5 {
  padding: 0;
  word-wrap: break-word;
  font-weight: bold;
}

.article_title.active:after {
  content: "...";
  position: absolute;
  top: 254px;
  right: 0;
  margin-right: 5%;
  font-size: 20px;
}

#DetailsPanel article .title {
  padding: 1em 0;
  font-weight: bold;
}

#DetailsPanel article img {
  max-width: 50%;
  float: left;
  padding: 0 2em 2em 0;
}

/* FAQ */
.faq-area .card-header {
  background: #fff;
  padding: 10px 15px 10px 15px;
}

.faq-area .card-header a {
  cursor: pointer;
  text-decoration: none;
  color: #303648;
}

.faq-area .card-header a h4 {
  background: url(/ReactClient/dist/assets/icon_arrow_up.png) no-repeat right center;
}

.faq-area .card-header a.collapsed h4 {
  background: url(/ReactClient/dist/assets/icon_arrow_down.png) no-repeat right center;
}

.faq-area .card-block {
  padding: 10px 15px 10px 15px;
  font-size: 14px;
}

.faq-area .panel-group {
  background: #fff;
  margin-top: 5px;
  margin-bottom: 0px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
/* Page support */
.page-support {
  box-sizing: border-box;
  padding: 25px 0;
  margin: 0 auto;
  position: relative;
}

.page-support-main {
  float: left;
  box-sizing: border-box;
  width: 75%;
  /*padding-right: 25px;*/
  border-right: 1px solid #dddfe3;
}

.page-support-aside {
  float: right;
  box-sizing: border-box;
  width: 25%;
  padding-left: 25px;
}

/* Changelog accordion */
.changelog-area {
}

.changelog-area .panel {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.changelog-area .panel-heading {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dddfe3;
}

.changelog-area .panel-body {
  background: transparent;
  border-top: 0 !important;
}

.changelog-area .panel-title {
  color: #303648;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.changelog-area .panel-title a {
  color: #303648;
}

.changelog-area .panel-title a:hover {
  text-decoration: none;
}

.changelog-area .toggle {
  color: #90949e;
  font-size: 14px;
  position: relative;
  top: 6px;
  text-decoration: underline;
}

/* Changelog aside */
.page-support-aside .box {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(64, 64, 64, 0.1);
  padding: 20px;
  margin-bottom: 35px;
}

.page-support-aside .box h2 {
  font-size: 18px;
  color: #303648;
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #f1f3f5;
  padding-bottom: 15px;
}

.page-support-aside .box h3 {
  font-size: 16px;
  color: #303648;
  margin-bottom: 18px;
  margin-top: 18px;
}

.page-support-aside nav {
  margin-bottom: 35px;
}

.page-support-aside nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.page-support-aside nav ul li {
  margin: 0 0 10px 0;
  padding: 0;
  border: none;
}

.page-support-aside nav ul a {
  display: block;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(64, 64, 64, 0.1);
  padding: 13px 15px 13px 58px;
  line-height: 22px;
  position: relative;
  font-size: 16px;
  color: #303648;
  border-radius: 4px;
}

.nav-tabs .nav-item {
  padding-right: 1px;
  padding-left: 1px;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid #ddd !important;
}

.nav-tabs .nav-link.active {
  color: #555;
  border-bottom: 2px solid #e3200f !important;
}

.nav-tabs .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #a3a7b0;
  border-bottom: 2px solid #e3200f !important;
}

.page-support-aside nav ul i {
  position: absolute;
  top: 50%;
  left: 18px;
  margin-top: -13px;
}

.page-support-aside .email-link {
  display: inline-block;
  color: #303648;
  font-size: 16px;
}

.page-support-aside .email-link i {
  position: relative;
  top: 4px;
}

.page-support-aside .social-tools {
  margin-top: 30px;
}

.changelog-tabs .nav-tabs li {
  width: 50%;
  text-align: center;
  font-size: 16px;
}

.changelog-tabs .nav-tabs li a {
  font-size: 16px;
  padding-top: 0;
  text-decoration: none;
}

.changelog-tabs a:hover,
.changelog-tabs a:focus,
.changelog-tabs a:active {
}

.icn-video {
  background-image: url(/ReactClient/dist/assets/icon_video.png);
  width: 25px;
  height: 22px;
  background-position: left center;
}

.icn-news {
  background-image: url(/ReactClient/dist/assets/icon_news.png);
  width: 25px;
  height: 22px;
  background-position: left center;
}

.icn-faq {
  background-image: url(/ReactClient/dist/assets/icon_faq.png);
  width: 25px;
  height: 22px;
  background-position: left center;
}

.icn-email {
  background-image: url(/ReactClient/dist/assets/icon_email.png);
  width: 25px;
  height: 22px;
  background-position: left center;
}

.contenteditable tr td {
  padding: 8px 14px 8px 14px !important;
}

.contenteditable tr td.content {
  padding-bottom: 6px !important;
}

.table-hover tr:hover td,
.table-hover tr.row-hover td {
  background: #edfdf9;
}

tr td span[contenteditable] {
  min-width: 20px;
  width: 94%;
  padding: 1px 1px 1px 3px;
  white-space: nowrap;
  overflow: hidden;
  /*display: block;*/
  display: inline-block;
  text-overflow: ellipsis;
}

tr:hover td span[contenteditable],
tr td span[contenteditable]:focus,
tr.row-hover td span[contenteditable]:focus {
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 0px 0px 0px 2px;
  max-width: 94%;
  overflow: hidden;
}

select[contenteditable]::-ms-expand {
  display: none;
}

tr > td > select[contenteditable] {
  display: inline-block;
  border: none;
  background-color: transparent;
  min-width: 20px;
  max-width: 800px;
  overflow-x: hidden;
  width: 90%;
  padding: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

tr:hover > td > select[contenteditable],
tr td select[contenteditable]:focus,
tr.row-hover td select[contenteditable]:focus {
  display: inline-block;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  background-color: white;
}

tr:hover > td > select[contenteditable] option {
  max-width: 400px;
  overflow-x: hidden;
  background-color: white !important;
  color: inherit;
}

.table-hover tr {
  cursor: pointer;
}

span.has-error[contenteditable] {
  background: #fbd8d8 !important;
  border: 1px solid red !important;
  border-radius: 4px !important;
}

table-fixed thead {
  width: 100%;
}

.table-fixed tbody {
  height: 600px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead th {
  display: inline-block;
  height: 41px;
  padding: 6px !important;
}

.table-fixed thead,
.table-fixed tbody {
  display: block;
}

.table-fixed tbody tr,
.table-fixed thead tr {
  display: block;
}

.table-fixed tr.row-hidden td {
  display: none;
}

.table-fixed tr.row-hidden.row-hover,
.table-fixed tr.row-hidden:hover {
  display: flex;
}

.table-fixed tr.row-hidden.row-hover td,
.table-fixed tr.row-hidden td span[contenteditable]:focus {
  display: flex;
}

.table-fixed tr.row-header td,
.table-fixed tr.row-header:hover td {
  background-color: transparent !important;
  font-size: 18px;
  font-weight: bold;
}

.table-fixed tbody tr td {
  display: inline-block;
  padding: 8px !important;
  height: 41px;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  border-bottom-width: 0px;
}

.table-fixed thead > tr > th {
  float: left;
  border-bottom-width: 0;
  cursor: default;
}

/*.table-fixed td[class*="col-"],
.table-fixed th[class*="col-"] {
    display: inline-block;
}*/

.btn.tooltiped[disabled] {
  pointer-events: all !important;
}

ol.carousel-indicators {
  margin: 0px;
  z-index: 1;
}

.carousel-indicators li {
  border-radius: 50%;
  height: 10px;
  max-width: 10px;
  background-color: #303648;
}

.carousel-indicators li.active {
  background-color: #e2000f;
}

.carousel-item img {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.carousel-item .form-header {
  padding-bottom: 0;
}

.carousel-inner {
  background-color: #f1f3f5;
  border-radius: 4px;
  height: 93%;
}

.carousel-caption {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  left: 0;
  text-align: left;
  padding: 30px 15px 0px 15px;
  color: #303648;
}

.news-panel {
  background-color: white;
  /*padding: 12px;*/
  margin: 12px 28px;
  border-radius: 4px;
  font-size: smaller !important;
  overflow: hidden;
}

.news-place {
  padding: 4px 12px;
}

.news-place p {
  height: 170px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}

.news-panel img {
  width: 100% !important;
}

.news-panel a.btn {
  left: 75%;
  margin-top: 22px;
  position: absolute;
}

.news-place h1,
.news-place h2,
.news-place h3,
.news-place h4,
.news-place h5 {
  margin-top: 16px;
  font-size: small;
  width: 100%;
}

.news-place h2 {
  margin-top: 16px;
  font-size: small;
  width: 100%;
}

/*.news-place img{
    width:50%;
}*/

.news-place p {
  margin-bottom: 0px;
}

.news-place div.readmore a {
  /*top: 370px;*/
  padding-right: 6px;
  color: red;
  width: 100%;
  /*float: right;*/
  /*position: absolute;*/
}

.item-hover {
  cursor: pointer;
  display: block;
  width: 240px;
  text-align: center;
  border-left: 2px solid transparent;
}

.item-hover-last {
  cursor: pointer;
  display: block;
  border-left: 2px solid transparent;
}

.item-hover:hover,
.item-hover.active {
  background-color: #edfdf9;
}

/*.item-divider{
    display: block;
    width:6px;
    padding:4px;
    background-color: transparent;
}*/

.item-hover.ui-droppable {
  border-left: 2px solid transparent;
}

.item-hover.ui-droppable.drag {
  border-left: 2px solid black;
}

div.ui-droppable.drag {
  border-top: 2px solid black;
}

.item-subheader {
  width: 100%;
  text-align: left;
  font-weight: bold;
  display: block;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  z-index: 9999;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.4);
}

.progress_bar_grey {
  color: #3a1a1a;
  display: inline-block;
  float: left;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #eceeef;
  width: 90%;
}

.progress_bar_green {
  color: #3a1a1a;
  display: inline-block;
  float: left;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #5cb85c;
  width: 90%;
}

/* Loading spinner */

.loading-spinner {
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.loading-spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

/* :not(:required) hides these rules from IE9 and below */
.loading-spinner:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading-spinner:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;
  border: 5px solid #e3200f;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui-autocomplete-loading {
  background: white url(/ReactClient/dist/assets/spinner.gif) right center no-repeat;
  background-size: 40px;
  background-position: ;
}
.ui-autocomplete {
  z-index: 1000;
  background-color: white;
  max-width: 400px;
  position: absolute;
  background-color: rgb(255, 255, 255);
}
.ui-menu-item {
  z-index: 1000;
  background-color: white;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: 600;
  line-height: 120%;
}
.ui-menu-item:hover {
  background-color: #e8e8e8;
}
